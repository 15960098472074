var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('DashboardHero',{staticClass:"mt-2 mb-5",attrs:{"title":_vm.appointment.prefix + '' + _vm.appointment.code,"type":"cover","background":"abstract-02"},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [(_vm.appointment.state == 'published')?_c('div',{staticClass:"px-3 py-1 rounded-full bg-primary bg-opacity-90"},[_c('div',{staticClass:"inline-flex flex-shrink-0 text-white relative gap-2"},[_c('div',{staticClass:"font-semibold"},[_vm._v("Chamada aberta")]),_c('fw-dot',{staticClass:"mt-1",attrs:{"color":"white","inline":""}})],1)]):(_vm.appointment.state == 'closed')?_c('div',[_c('div',{staticClass:"text-sm opacity-70 font-medium"},[_vm._v(" Chamada terminada ")])]):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [_c('div',[_c('fw-label',{attrs:{"color":"text-white opacity-50"}},[_vm._v(_vm._s(_vm.$t('createdAt')))]),_c('div',[_vm._v(_vm._s(_vm._f("formatDateTime")(_vm.appointment.created_date)))])],1),(_vm.appointment.state === 'published')?_c('div',[_c('fw-label',{attrs:{"color":"text-white opacity-50"}},[_vm._v(_vm._s(_vm.$t('publishedAt')))]),_c('div',[_vm._v(_vm._s(_vm._f("formatDate")(_vm.appointment.published_date)))])],1):_vm._e(),(_vm.appointment.state === 'closed')?_c('div',[_c('fw-label',{attrs:{"color":"text-white opacity-50"}},[_vm._v(_vm._s(_vm.$t('closedAt')))]),_c('div',[_vm._v(_vm._s(_vm._f("formatDate")(_vm.appointment.closed_date)))])],1):_vm._e()]},proxy:true}])}),_c('fw-panel',{staticClass:"my-5",attrs:{"title":_vm.$t('statusMainBoardTitle')}},[_c('div',{staticClass:"flex flex-col gap-4"},[_c('div',{staticClass:"flex gap-3 text-gray-500"},[_vm._l((_vm.appointmentStatus),function(status,key){return [_c('div',{key:key,staticClass:"relative rounded-xl bg-white border p-4 flex flex-col text-center font-semibold items-center gap-1 group",class:{
              'w-2/5 border-primary min-h-28': status.isRunning,
              'w-1/3 border-white min-h-28': !status.isRunning,
              'text-primary': status.isActive,
            }},[_c('div',{staticClass:"w-full flex items-center justify-center"},[(status.isDone)?_c('div',[_c('fw-icon-check-solid',{staticClass:"w-6 h-6 flex-shrink-0"})],1):(status.isRunning)?_c('div',[_c('fw-icon-run',{staticClass:"w-6 h-6 flex-shrink-0 text-primary animate-pulse"})],1):_c('div',[_c('fw-icon-rest',{staticClass:"w-6 h-6 flex-shrink-0 text-gray-300"})],1)]),_c('div',{staticClass:"flex-1",class:{
                'font-bold': status.isActive || status.isDone,
              }},[_vm._v(" "+_vm._s(status.label)+" ")]),_c('div',{staticClass:"flex justify-center items-center font-normal text-xs"},[(status.isRunning)?_c('div',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.$t('phaseOngoing'))+" ")]):(status.isDone && key !== 'closed')?_c('div',[_vm._v(" "+_vm._s(_vm.$t('phaseFinished'))+" ")]):(!status.isDone && !status.isActive)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('phasePending'))+" ")]):(key === 'closed' && status.isDone)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('phaseClosed'))+" ")]):_vm._e()])])]})],2)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }