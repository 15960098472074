<template>
  <div class="w-full h-full flex">
    <!-- Sidebar -->
    <SidebarReservationsList
      class="w-80 2xl:w-96 h-full flex flex-col"
      :reservations="reservations.reservations"
      :users="users"
      :active-reservation="reservation ? reservation.key : 'none'"
      :pagination="reservations.pagination"
      @page-changed="$emit('page-changed', $event)"
    ></SidebarReservationsList>
    <!-- Main -->
    <div
      v-if="reservation"
      class="flex-1 h-full border-l border-gray-200 relative flex"
      :class="{ 'blur-sm opacity-40': loading }"
    >
      <!-- Reservation main -->
      <div v-if="reservation" class="px-10 py-5 flex-1 2xl:w-3/4 overflow-auto">
        <fw-heading size="h3" muted class="mb-5"
          >Agendamento a
          <span v-if="reservation.time_slot"
            >{{ reservation.time_slot.start_datetime | formatDateTime }} -
            {{ reservation.time_slot.end_datetime | formatDateTime }}</span
          >
          <span v-else>{{ appointment.prefix }} {{ appointment.code }}</span></fw-heading
        >

        <div>
          <BlockUserDetails
            class="text-left mb-5"
            :user="users[reservation.user_key]"
            :paddingless="true"
            :only-header="true"
          ></BlockUserDetails>
        </div>

        <PanelManageReservationSlots
          :saving-data.sync="savingData['metadata']"
          :reservation.sync="reservation"
          :appointment="appointment"
          :slots="slots"
          :users="users"
          :can-edit="canEdit"
          class="pb-5"
          @save="updateReservationSlot"
        ></PanelManageReservationSlots>

        <fw-panel
          v-if="reservation.active_reservation"
          title="Informações da equipa clínica"
          boxed="sm"
          custom-class="flex flex-col gap-2"
        >
          <div>
            <fw-label>Presença</fw-label>
            <div
              :class="{
                'text-gray-500 text-sm': reservation.active_reservation?.present === null,
                'font-semibold': reservation.active_reservation?.present !== null,
              }"
            >
              {{
                reservation.active_reservation?.present === true
                  ? 'Presente'
                  : reservation.active_reservation?.present === null
                  ? 'A presenção ou ausência ainda não foi confirmada.'
                  : 'Ausente'
              }}
            </div>
          </div>
          <div>
            <fw-label>Comentários</fw-label>
            <div
              v-if="reservation.active_reservation.medical_observations"
              class="html-content"
              v-html="reservation.active_reservation.medical_observations"
            ></div>
            <div v-else class="text-gray-500 text-sm">Não foram definidos comentários.</div>
          </div>
        </fw-panel>

        <fw-panel class="mt-10" title="Atividade">
          <b-tabs v-model="activeTab" :animated="false" @input="getTabData">
            <b-tab-item :label="$t('messages')" value="messages">
              <PanelMessages
                :users="messagesUsers"
                :messages="messages"
                :pagination="paginationMessages"
                :loading="loadingMessages"
                :show-public-private-toggle="canSendMessages"
                :force-private-message="!canSendPublicMessages"
                :force-hide-private-messages="false"
                :can-add-message="canSendMessages"
                :default-add-message-is-private="true"
                :copy-files-label="'Copiar ficheiros para recibos'"
                @page-changed="messagesPageChanged"
                @add-message="addMessage"
                @update-message="updateMessage"
                @delete-message="deleteMessage"
              />
            </b-tab-item>
            <b-tab-item :label="$t('logs')" value="logs">
              <PanelReservationActivity
                :activity="logs"
                :users="activityUsers"
                :pagination="paginationActivity"
                :loading="loadingActivity"
                class="bg-white rounded-xl"
                @page-changed="activityPageChanged"
              ></PanelReservationActivity>
            </b-tab-item>
          </b-tabs>
        </fw-panel>
      </div>
      <!-- Reservation sidebar -->
      <div class="p-5 w-72 2xl:w-80 flex flex-col gap-5">
        <div>
          <fw-label>Estado do agendamento</fw-label>
          <div>
            <fw-tag
              v-if="reservationStates[reservation.state]"
              expanded
              size="lg"
              :type="reservationStates[reservation.state].color"
              >{{ reservationStates[reservation.state].label[language] }}</fw-tag
            >
          </div>
        </div>

        <div v-if="canCancel">
          <fw-button :type="'border-light'" expanded @click.native="cancelReservation">Cancelar</fw-button>
        </div>

        <div class="border-t pt-2">
          <div class="flex gap-2 justify-between">
            <div>
              <fw-label>{{ $t('assignees') }}</fw-label>
            </div>
            <div>
              <fw-button v-if="canEditAssignees && canEdit" type="link" @click.native="chooseAssignees = true">{{
                $t('addAssignees')
              }}</fw-button>
            </div>
          </div>
          <div v-if="reservation.managers && reservation.managers.length">
            <div v-for="user_key in reservation.managers" :key="user_key" class="flex gap-2 px-2 py-1 items-center">
              <div class="flex-1 flex gap-3 items-center">
                <fw-avatar
                  v-if="assigneedUsers[user_key]"
                  size="xs"
                  :user="assigneedUsers[user_key]"
                  class="flex-shrink-0"
                />
                <v-clamp v-if="assigneedUsers[user_key]" class="font-semibold text-sm" autoresize :max-lines="1">
                  {{ assigneedUsers[user_key].full_name }}
                </v-clamp>
              </div>
              <div v-if="canEditAssignees && canEdit" class="flex-shrink-0 opacity-70 hover:opacity-100">
                <fw-button type="xlight" label="Remover" @click.native="deleteAssignee(user_key)">
                  <fw-icon-close-circle class="h-5 w-5" />
                </fw-button>
              </div>
            </div>
          </div>
          <div v-else class="text-gray-500 text-sm">{{ $t('notDefined') }}.</div>
        </div>

        <div class="flex flex-col gap-3 text-sm border-t pt-3 text-gray-600">
          <div>
            <fw-label marginless>Chave do agendamento</fw-label>
            <div>{{ reservation.key }}</div>
          </div>
          <div>
            <fw-label marginless>Data de criação</fw-label>
            <div>{{ reservation.created_date | formatDateTime }}</div>
          </div>
          <div>
            <fw-label marginless>Data de submissão</fw-label>
            <div class="flex items-center gap-1">
              <span>{{ reservation.submitted_date | formatDateTime }}</span>
              <fw-icon-checkbox-circle class="w-5 h-5 text-primary" />
            </div>
          </div>
          <div v-if="reservation.canceled_date">
            <fw-label marginless>{{ $t('deletedAt') }}</fw-label>
            <div>{{ reservation.canceled_date | formatDateTime }}</div>
          </div>
          <div>
            <fw-label marginless>Número mecanográfico</fw-label>
            <div>
              {{
                users && reservation && users[reservation.user_key] && users[reservation.user_key].number
                  ? users[reservation.user_key].number
                  : 'Não existente'
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <fw-modal :active.sync="chooseAssignees" size="auto" boxed="xs" @close="closeModalChooseAssignees">
      <ModalChooseAssignees
        v-if="chooseAssignees"
        :managers="reservation.managers"
        :reservation-key="reservation.key"
        :appointment-key="appointment.key"
        :users="assigneedUsers"
        :available-users="assigneesList"
        @close="closeModalChooseAssignees"
        @add="addNewAssignees"
      ></ModalChooseAssignees>
    </fw-modal>
  </div>
</template>

<script>
import BlockUserDetails from '@/fw-modules/fw-core-vue/id/components/blocks/BlockUserDetails'
import PanelManageReservationSlots from '@/components/panels/manage/occupationalMedicine/PanelManageReservationSlots'
import PanelReservationActivity from '@/components/panels/manage/occupationalMedicine/PanelReservationActivity'
import ModalChooseAssignees from '@/components/modals/ModalChooseAssignees'
import PanelMessages from '@/fw-modules/fw-core-vue/ui/components/panels/PanelMessages'
import { RESERVATION_STATES, APPLICATION_REJECTED_REASONS } from '@/utils/index.js'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import SidebarReservationsList from '@/components/sidebars/occupationalMedicine/SidebarReservationsList'

export default {
  components: {
    PanelReservationActivity,
    PanelMessages,
    ModalChooseAssignees,
    SidebarReservationsList,
    PanelManageReservationSlots,
    BlockUserDetails,
  },

  props: {
    reservations: {
      type: Object,
      default: () => {},
    },
    reservation: {
      type: Object,
      default: () => {},
    },
    appointment: {
      type: Object,
      default: () => {},
    },
    users: {
      type: Object,
      default: () => {},
    },
    validations: {
      type: Object,
      default: () => {},
    },
    peopleValidations: {
      type: Object,
      default: () => {},
    },
    slots: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    allowCopyFiles: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const emptyMessage = { description: '', is_private: false, files: [] }
    return {
      isActiveSlotsModal: false,
      loadingMessages: false,
      loadingActivity: false,
      savingData: {
        metadata: false,
        additional_data: false,
        rejected_reasons: false,
        copyfiles: false,
      },
      dirtyData: {
        additional_data: false,
        rejected_reasons: false,
      },
      expandedMetadata: ['draft', 'submitted'].includes(this.reservation.state),
      activeTab: 'messages',
      activeManagementTab: 'metadata',
      availableAssignees: [],
      assigneedUsers: {},
      activityUsers: {},
      messagesUsers: {},
      userAgents: {},
      chooseAssignees: false,
      logs: [],
      messages: [],
      canSendMessages: false,
      canSendPublicMessages: false,
      reservationStates: RESERVATION_STATES,
      availableRejectedReasons: APPLICATION_REJECTED_REASONS,
      emptyMessage: emptyMessage,
      rejectMessage: emptyMessage,
      acceptMessage: emptyMessage,
      isModalRejectMessageActive: false,
      isModalAcceptMessageActive: false,
      isModalEditMetadataActive: false,
      paginationActivity: {
        // TODO:
        page: 1,
        totalResults: 0,
        totalPages: 1,
        limit: 50,
      },
      paginationMessages: {
        // TODO:
        page: 1,
        totalResults: 0,
        totalPages: 1,
        limit: 50,
      },
      //Files from a message to copy to receipts
      messageToCopyFilesFrom: null,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    language() {
      return this.$store.state.language
    },
    assigneesList() {
      let managers = Array.isArray(this.reservation.managers) ? this.reservation.managers : []
      return this.availableAssignees.filter(el => !managers.includes(el)).map(user_key => this.assigneedUsers[user_key])
    },
    appointmentKey() {
      return this.$route.params.key
    },
    reservationKey() {
      return this.$route.params.reservationKey
    },
    canEditAssignees() {
      return (
        this.peopleValidations && (this.peopleValidations.can_edit_managers || this.peopleValidations.can_edit_workers)
      )
    },
    canEdit() {
      return this.validations && this.validations.can_edit
    },
    canCancel() {
      return this.canEdit && this.reservation.state == 'submitted'
    },
  },

  watch: {
    reservationKey(loadAppkey) {
      if (loadAppkey !== this.reservation.key) this.loadInitialData()
    },
  },

  async mounted() {
    this.loadInitialData()
  },

  methods: {
    async updateReservationSlot(newSlotKey) {
      console.log('startReservation :>> ')
      if (this.reservation.slot_key == newSlotKey) return

      this.loading = true

      try {
        const response = await this.api.updateReservation(this.appointmentKey, this.reservationKey, {
          time_slot: newSlotKey,
        })
        console.log('updateReservation :>> ', response)
        this.$emit('updated-reservation', response)
        this.canSendMessages = response.validations.can_send_message
        this.canSendPublicMessages = response.validations.can_send_public_message

        this.$emit('page-changed', this.reservations.pagination.current_page)
        return this.$router.push({
          name: 'manage-appointment-reservation',
          params: {
            key: this.appointmentKey,
            reservationKey: response.reservation.key,
          },
        })
      } catch (error) {
        console.log('Error updateReservation :>> ', error)
        const errorKey = utils.errors(error).getKey()
        if (errorKey === 'SlotUnavailable') {
          this.$buefy.dialog.alert({
            title: 'Ocorreu um erro',
            message: `<div class="mb-3">O Horário selcionado já não está disponivel.</div>`,
            type: 'is-danger',
          })
        } else {
          this.$buefy.snackbar.open({
            message: 'Ocorreu um erro ao tentar atualizar o agendamento.',
            type: 'is-danger',
          })
        }
      }

      this.loading = false
    },

    loadInitialData() {
      this.getAssigneesList()
      this.getTabData(this.activeTab)
    },

    closeModalChooseAssignees() {
      this.chooseAssignees = false
    },

    closeEditMetadataModal() {
      this.isModalEditMetadataActive = false
    },

    downloadFile(file) {
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
    },

    async deleteAssignee(userKey) {
      console.log('deleteAssignee :>> ', userKey)
      try {
        const response = await this.api.deleteReservationManagers(this.appointmentKey, this.reservationKey, [userKey])
        console.log('deleteReservationManagers :>> ', response)
        this.$emit('update-reservation-managers', response.reservation)
      } catch (error) {
        console.log('Error deleteReservationManagers', error)
        this.$buefy.dialog.alert({
          title: this.$t('errorOccurred.title'),
          message: this.$t('errorOccurred.message'),
          type: 'is-danger',
        })
      }
    },

    async getAssigneesList() {
      try {
        //const response = await this.api.getReservationManagers(this.appointmentKey, this.reservationKey)
        const response = await this.api.getReservationManagers(this.appointmentKey, this.reservationKey)
        console.log('getReservationManagers :>> ', response)
        this.availableAssignees = Object.keys(response.managers)
        this.assigneedUsers = response.users
      } catch (error) {
        console.log('Error getReservationManagers', error)
      }
    },

    async addNewAssignees(newManagersKeys) {
      console.log('addNewAssignees :>> ', newManagersKeys)
      try {
        const response = await this.api.createReservationManagers(
          this.appointmentKey,
          this.reservationKey,
          newManagersKeys
        )
        console.log('createReservationManagers :>> ', response)
        this.$emit('update-reservation-managers', response.reservation)
        //update managers
      } catch (error) {
        this.$buefy.dialog.alert({
          title: this.$t('errorOccurred.title'),
          message: this.$t('errorOccurred.message'),
          type: 'is-danger',
        })
        console.log('Error createReservationManagers', error)
      }
    },

    async changeStatus(newStatus) {
      console.log('changeStatus :>> ', newStatus)
      this.loading = true
      try {
        const response = await this.api.updateReservation(this.appointmentKey, this.reservationKey, {
          state: newStatus,
        })
        console.log('response :>> ', response)
        this.$emit('updated-reservation', response)
        this.canSendMessages = response.validations.can_send_message
        this.canSendPublicMessages = response.validations.can_send_public_message
        this.$emit('page-changed', this.reservations.pagination.current_page)
      } catch (error) {
        console.error('Error changeStatus', error)
        this.$buefy.dialog.alert({
          title: this.$t('errorOccurred.title'),
          message: this.$t('errorOccurred.message'),
          type: 'is-danger',
        })

        this.loadingError = true
      }
      this.loading = false
    },

    async getTabData(tab) {
      console.log('getTabData :>> ', tab)
      if (tab == 'messages') this.getMessages()
      else if (tab == 'logs') this.getActivity()
    },

    async getMessages() {
      this.loadingMessages = true

      try {
        console.log('getMessages params :>> ', this.paginationMessages)
        const response = await this.api.getReservationMessages(this.appointmentKey, this.reservationKey, {
          page: this.paginationMessages.page,
          limit: this.paginationMessages.limit,
        })
        console.log('getMessages :>> ', response)
        this.canSendMessages = response.validations.can_send_message
        this.canSendPublicMessages = response.validations.can_send_public_message
        this.messages = response.messages
        this.messagesUsers = { ...this.messagesUsers, ...response.users }
        this.paginationMessages = {
          limit: response.pagination.active_limit,
          page: response.pagination.current_page,
          totalResults: response.pagination.total_items,
          totalPages: response.pagination.total_pages,
        }
      } catch (e) {
        console.log('Error getMessages :>> ', e)
      }

      this.loadingMessages = false
    },

    messagesPageChanged(page) {
      console.log('messagesPageChanged :>> ', page)
      this.paginationMessages.page = page
      this.getMessages()
    },

    async getActivity() {
      this.loadingActivity = true

      try {
        console.log('getReservationActivity params :>> ', this.paginationActivity)
        const response = await this.api.getReservationActivity(this.appointmentKey, this.reservationKey, {
          page: this.paginationActivity.page,
          limit: this.paginationActivity.limit,
        })
        console.log('getReservationActivity :>> ', response)
        this.logs = response.logs
        this.activityUsers = { ...this.activityUsers, ...response.users }
        this.paginationActivity = {
          limit: response.pagination.active_limit,
          page: response.pagination.current_page,
          totalResults: response.pagination.total_items,
          totalPages: response.pagination.total_pages,
        }
      } catch (e) {
        console.log('Error getReservationActivity :>> ', e)
      }

      this.loadingActivity = false
    },

    activityPageChanged(page) {
      console.log('activityPageChanged :>> ', page)
      this.paginationActivity.page = page
      this.getActivity()
    },

    async addMessage(messageData, type = null) {
      console.log('messageData :>> ', messageData)
      try {
        const response = await this.api.addReservationMessages(this.appointmentKey, this.reservationKey, messageData)
        console.log('response :>> ', response)
        console.log('addedMessage :>> ', response)
        this.messages.unshift(response.message)
        this.$emit('added-message', response)

        if (type === 'rejected') {
          this.closeAddRejectMessageModal()
        }
      } catch (error) {
        this.savingMessageError = true
        const errorKey = utils.errors(error).getKey()
        console.log('error :>> ', error)
        this.$buefy.dialog.alert({
          title: 'Ocorreu um erro',
          message: `Não foi possível guardar a mensagem: ${errorKey}`,
          type: 'is-danger',
          duration: 4000,
        })
      }
    },

    async updateMessage(messageData) {
      utils.tryAndCatch(this, async () => {
        const response = await this.api.updateReservationMessage(
          this.appointmentKey,
          this.reservationKey,
          messageData.key,
          messageData
        )
        console.log('updatedMessage :>> ', response)
        let index = this.messages.findIndex(el => el.key == response.key)
        if (index > -1) this.$set(this.messages, index, response)
      })
    },

    async deleteMessage(message) {
      utils.tryAndCatch(this, async () => {
        const response = await this.api.deleteReservationMessage(this.appointmentKey, this.reservationKey, message.key)
        console.log('deleteReservationMessage :>> ', response)
        this.messages = this.messages.filter(el => el.key != message.key)
      })
    },

    // Grab custom reject message and take action
    cancelReservation() {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        title: 'Cancelar agendamento',
        message: 'Tem a certeza que pretende cancelar o agendamento? A aplicação irá notificar o trabalhador  .....',
        onConfirm: async () => {
          this.changeStatus('canceled')
        },
        confirmText: 'Cancelar agendamento',
        cancelText: 'Fechar',
      })
    },

    openModal(type, message) {
      console.log('openModal :>> ', type, message)
      this.activeMessage = message
      this.activeModal = type
    },

    getEmptyMessage() {
      return {
        description: '',
        is_private: false,
        files: [],
      }
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "receipts": "Recibos",
    "deletedAt": "Eliminado em",
    "has_different_address":  "Morada diferente",
    "fileTypes": {
      "household_fiscal_address": "Comprovativo de morada",
      "family_allowance": "Comprovativo de atribuição de abono de família",
      "permanent_residence": "Comprovativo de residência permanente",
      "non_existence_of_debts": "Comprovativo de não existência de dívidas",
      "payment_receipts": "Comprovativo de pagamento",
      "iban": "Comprovativo de IBAN"
    },
    "identityTypes": {
      "cc": "Cartão de Cidadão",
      "passport": "Passaporte",
      "residence_permit": "Título de residência"
    },
    "studyCycles": {
      "1st": "1º Ciclo - Licenciatura",
      "2nd": "2º Ciclo - Mestrado",
      "1st-2nd": "1º e 2º Ciclo - Mestrado Integrado",
      "3rd": "3º Ciclo - Doutoramento"
    },
    "user": "Requerente",
    "noFiles": "Sem ficheiros",
    "files": "Ficheiros",
    "appointment": "Procedimento",
    "subject": "Assunto",
    "logs": "Auditoria",
    "activity": "Atividade",
    "description": "Descrição",
    "title": "Titulo",
    "createdAt": "Criado em",
    "openAt": "Aberto em",
    "closedAt": "Fechado em",
    "messages": "Mensagens",
    "assignees": "Responsáveis",
    "addAssignees": "Adicionar",
    "notDefined": "Não definido",
    "errorOccurred": {
      "title": "Ocorreu um erro",
      "message": "Ocorreu um erro não esperado ao guardar a candidatura."
    },
    "errorOccuredSavingMessage": "Ocorreu um erro não esperado ao guardar a mensagem."
  },
  "en": {
    "receipts": "Receipts",
    "deletedAt": "Deleted at",
    "has_different_address":  "Different address",
    "identityTypes": {
      "cc": "Citizen ID card",
      "passport": "Passport",
      "residencePermit": "Residence permit"
    },
    "studyCycles": {
      "1st": "1º Ciclo - Licenciatura",
      "2nd": "2º Ciclo - Mestrado",
      "1st-2nd": "1º e 2º Ciclo - Mestrado Integrado",
      "3rd": "3º Ciclo - Doutoramento"
    },
    "fileTypes": {
      "household_fiscal_address": "Proff of household fiscal address",
      "family_allowance": "Proff of family allowance",
      "permanent_residence": "Proff of permanent residence",
      "non_existence_of_debts": "Proff of non existence of debts",
      "payment_receipts": "Proff of payment receipts",
      "iban": "Proff of IBAN"
    },
    "user": "User",
    "noFiles": "No files",
    "files": "Files",
    "appointment": "appointment",
    "subject": "Subject",
    "logs": "Logs",
    "activity": "Activity",
    "description": "Description",
    "title": "Title",
    "createdAt": "Created at",
    "openAt": "Opened at",
    "closedAt": "Closed at",
    "assignees": "Assignees",
    "messages": "Messages",
    "addAssignees": "Add",
    "notDefined": "Not defined",
    "errorOccurred": {
      "title": "An error has occurred",
      "message": "An unexpected error occurred while saving the reservation."
    },
    "errorOccuredSavingMessage": "An unexpected error occurred while saving the message."
  }
}
</i18n>
