<template>
  <fw-panel v-if="timeSlot" :title="'Adicionar membro de equipa'" class="m-5">
    <form class="mb-3" @submit.prevent="updateSlot">
      <fw-label>Número de vagas</fw-label>
      <div>
        <NumberInput v-model="availability" :min="1" :max="100" placeholder="Insira o número de vagas" />
      </div>
      <fw-tip v-if="$v.availability.$error" error>
        <span v-if="!$v.availability.min">{{ $t('availability.min', { val: minAvailabilityValue }) }}</span>
        <span v-else>{{ $t('availability.required') }}</span>
      </fw-tip>
    </form>
    <fw-button type="primary" class="flex ml-auto" :loading="saving" @click.native="updateSlot">
      Guardar
    </fw-button>

    <fw-panel-info debug label="Data (raw)">
      <json-viewer :value="{ timeSlot }"></json-viewer>
    </fw-panel-info>
  </fw-panel>
</template>

<script>
import { required, minValue } from 'vuelidate/lib/validators'
import NumberInput from '@/fw-modules/fw-core-vue/ui/components/form/NumberInput'

export default {
  components: {
    NumberInput,
  },

  props: {
    timeSlot: {
      type: Object,
      default: () => {},
    },
    saving: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      availability: null,
    }
  },

  validations() {
    return {
      availability: {
        required,
        min: minValue(this.minAvailabilityValue),
      },
    }
  },

  computed: {
    minAvailabilityValue() {
      return this.timeSlot?.reservations?.length || 1
    },
  },

  mounted() {
    this.availability = this.timeSlot.availability
  },

  methods: {
    async updateSlot() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.$emit('update', this.availability)
      this.$v.$reset()
      this.availability = null
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "availability": {
      "label": "Número de vagas",
      "min": "Insira um valor maior ou igual a {val}",
      "required": "Insira o número de vagas"
    }
  },
  "en": {
    "availability": {
      "label": "Número de vagas",
      "min": "Insira um valor maior ou igual a {val}",
      "required": "Insira o número de vagas"
    }
  }
}
</i18n>
