<template>
  <div>
    <LoadingPlaceholder v-if="loading" />

    <fw-panel v-else title="Gestor(es)" boxed="xs" custom-class="bg-white" class="my-5" :counter="managersCount">
      <ExpandList
        add-title="Adicionar gestor(es)"
        :empty="!managers || managersCount < 1"
        :show-add-items="canEditManagers"
        @add-element="choosePeople('manager')"
      >
        <template #list>
          <Person
            v-for="(manager, index) in managers"
            :key="manager.key + 'manager'"
            :class="{
              'border-b border-gray-100': index < managersCount - 1,
            }"
            :open-details-on-click="canEditManagers"
            :person="manager"
            :selectable="false"
            :clickable="canEditManagers"
          >
            <template v-if="canEditManagers" #options>
              <b-dropdown aria-role="list" position="is-bottom-left">
                <fw-button-dropdown slot="trigger" :chevron="false" type="simple" class="flex flex-col" label="Ações">
                  <fw-icon-more class="w-5 h-5" />
                </fw-button-dropdown>
                <b-dropdown-item custom aria-role="menuitem" class="paddingless">
                  <fw-button type="basic-action" expanded @click.native="deleteManager(manager.key, 'manager')"
                    >Remover</fw-button
                  >
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </Person>
          <div v-if="managers.length == 0 && !canEditManagers" class="text-center text-gray-400 py-14 text-sm">
            Sem gestores associados.
          </div>
        </template>
      </ExpandList>
    </fw-panel>

    <fw-panel title="Operador(es)" boxed="xs" custom-class="bg-white" class="my-5" :counter="workersCount">
      <ExpandList
        add-title="Adicionar operador(es)"
        :empty="!workers || workersCount < 1"
        :show-add-items="canEditWorkers"
        @add-element="choosePeople('worker')"
      >
        <template #list>
          <Person
            v-for="(worker, index) in workers"
            :key="worker.key + 'worker'"
            :class="{
              'border-b border-gray-100': index < workersCount - 1,
            }"
            :open-details-on-click="canEditWorkers"
            :person="worker"
            :selectable="false"
            :clickable="canEditWorkers"
          >
            <template v-if="canEditWorkers" #options>
              <b-dropdown aria-role="list" position="is-bottom-left">
                <fw-button-dropdown slot="trigger" :chevron="false" type="simple" class="flex flex-col" label="Ações">
                  <fw-icon-more class="w-5 h-5" />
                </fw-button-dropdown>
                <b-dropdown-item custom aria-role="menuitem" class="paddingless">
                  <fw-button type="basic-action" expanded @click.native="deleteManager(worker.key, 'worker')"
                    >Remover</fw-button
                  >
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </Person>
          <div v-if="workers.length == 0 && !canEditWorkers" class="text-center text-gray-400 py-14 text-sm">
            Sem operadores associados.
          </div>
        </template>
      </ExpandList>
      <template #footer>
        <fw-panel-info size="xs" icon clean
          >Apenas os operadores podem ser associados como responsáveis por candidaturas.</fw-panel-info
        >
      </template>
    </fw-panel>

    <fw-panel title="Visualizador(es)" boxed="xs" custom-class="bg-white" class="my-5" :counter="viewersCount">
      <ExpandList
        add-title="Adicionar visualizador(es)"
        :empty="!viewers || viewersCount < 1"
        :show-add-items="canEditViewers"
        @add-element="choosePeople('viewer')"
      >
        <template #list>
          <Person
            v-for="(viewer, index) in viewers"
            :key="viewer.key + 'viewers'"
            :class="{
              'border-b border-gray-100': index < viewersCount - 1,
            }"
            :open-details-on-click="canEditViewers"
            :person="viewer"
            :selectable="false"
            :clickable="canEditViewers"
          >
            <template v-if="canEditViewers" #options>
              <b-dropdown aria-role="list" position="is-bottom-left">
                <fw-button-dropdown slot="trigger" :chevron="false" type="simple" class="flex flex-col" label="Ações">
                  <fw-icon-more class="w-5 h-5" />
                </fw-button-dropdown>
                <b-dropdown-item custom aria-role="menuitem" class="paddingless">
                  <fw-button type="basic-action" expanded @click.native="deleteManager(viewer.key, 'viewer')"
                    >Remover</fw-button
                  >
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </Person>
          <div v-if="viewers.length == 0 && !canEditViewers" class="text-center text-gray-400 py-14 text-sm">
            Sem visualizadores associados.
          </div>
        </template>
      </ExpandList>
      <template #footer>
        <fw-panel-info size="xs" icon clean>Um visualizador apenas pode consultar a informação.</fw-panel-info>
      </template>
    </fw-panel>

    <fw-panel-info debug label="Data (raw)" class="mt-5">
      <json-viewer :value="{ validations, managers, workers, excludeUsers }"></json-viewer>
    </fw-panel-info>

    <b-modal
      :active="!!choosingPeopleType && !activeUserDetailsModal"
      scroll="keep"
      :can-cancel="true"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="700"
      :on-cancel="closeModal"
      :custom-class="'rounded-buefy-modal'"
    >
      <ChoosePeopleModal
        :title="selectPeopleTitle"
        :instructions="selectPeopleInstructions"
        :multiselect="true"
        :not-alowed-users="excludeUsers"
        :invite-people="false"
        :endpoint="appointmentManagersSearch"
        @selected="selectedappointmentManagers"
        @close="closeModal"
      ></ChoosePeopleModal>
      <ModalChooseQueuesManagersModal
        v-if="false"
        :title="selectPeopleTitle"
        :instructions="selectPeopleInstructions"
        :multiselect="true"
        :endpoint="appointmentManagersSearch"
        :not-alowed-users="excludeUsers"
        :queues="space.queues"
        :space-key="space.key"
        @selected="selectedQueuesManagers"
        @close="closeModal"
      ></ModalChooseQueuesManagersModal>
    </b-modal>

    <b-modal
      :active="activeUserDetailsModal"
      scroll="keep"
      :can-cancel="true"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="700"
      :on-cancel="closePersonDetails"
      :custom-class="'rounded-buefy-modal'"
    >
      <UserDetails
        v-if="activeUserDetailsModal"
        :user="selectedUser"
        :saving-data="savingData"
        @close="closePersonDetails"
      />
    </b-modal>
  </div>
</template>

<script>
import ExpandList from '@/fw-modules/fw-core-vue/ui/components/lists/ExpandList'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'
import ChoosePeopleModal from '@/fw-modules/fw-core-vue/ui/components/modals/ChoosePeopleModal'
import ModalChooseQueuesManagersModal from '@/components/modals/ModalChooseQueuesManagers'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import UserDetails from '@/components/modals/UserDetails'

export default {
  components: {
    ExpandList,
    Person,
    ChoosePeopleModal,
    ModalChooseQueuesManagersModal,
    LoadingPlaceholder,
    UserDetails,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    savingData: {
      type: Boolean,
      default: false,
    },
    appointment: {
      type: Object,
      default: () => {},
    },
    validations: {
      type: Object,
      default: () => {
        return {
          can_edit: false,
        }
      },
    },
    users: {
      type: Object,
      default: () => {},
    },
    managersKeys: {
      type: Array,
      default: () => [],
    },
    workersKeys: {
      type: Array,
      default: () => [],
    },
    viewerKeys: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      activeUserDetailsModal: false,
      activeUserDetailsRefresh: false,
      selectedUser: {},
      choosingPeopleType: null,
      choosingPeopleQueue: null,
      choosingPeopleTitles: {
        manager: {
          title: 'Adicionar gestor(es)',
          instructions: 'Indique um(a) ou mais utilizadores(as) para adicionar como gestor(a) neste procedimento.',
        },
        worker: {
          title: 'Adicionar operador(es)',
          instructions: 'Indique um(a) ou mais utilizadores(as) para adicionar como operador(a) neste procedimento.',
        },
      },
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    loggedUser() {
      return this.$store.getters.getUser
    },

    selectPeopleTitle() {
      return this.choosingPeopleType ? this.choosingPeopleTitles[this.choosingPeopleType].title : ''
    },

    selectPeopleInstructions() {
      return this.choosingPeopleType ? this.choosingPeopleTitles[this.choosingPeopleType].instructions : ''
    },

    excludeUsers() {
      if (this.choosingPeopleType == 'manager') return this.managers.map(el => el.key)
      else if (this.choosingPeopleType == 'viewer') return this.viewers.map(el => el.key)
      else return this.workers.map(el => el.key)
    },

    managersCount() {
      return this.managers.length
    },

    workersCount() {
      return this.workers.length
    },

    viewersCount() {
      return this.viewers.length
    },

    canEditManagers() {
      return this.validations && this.validations.can_edit_managers === true ? true : false
    },

    canEditWorkers() {
      return this.validations && this.validations.can_edit_workers === true ? true : false
    },

    canEditViewers() {
      return this.validations && this.validations.can_edit_viewers === true ? true : false
    },

    isOwner() {
      return this.appointment.user_key == this.loggedUser.key
    },

    isManager() {
      return this.managersKeys.includes(this.loggedUser.key)
    },

    managers() {
      return this.managersKeys.map(el => {
        return this.users[el]
      })
    },

    workers() {
      return this.workersKeys.map(el => {
        return this.users[el]
      })
    },

    viewers() {
      return this.viewerKeys.map(el => {
        return this.users[el]
      })
    },
  },

  methods: {
    async appointmentManagersSearch(params) {
      return await this.api.searchAppointmentUsers(this.appointment.key, params)
    },

    findQueue(queueKey) {
      return this.space.queues.find(el => el.key == queueKey)
    },

    choosePeople(type) {
      console.log('choosePeople :>> ', type)
      this.choosingPeopleType = type
    },

    openPersonDetails(user, roleType) {
      console.log('openPersonDetails :>> ', user, roleType)
      this.selectedUser = user
      this.activeUserDetailsRefresh = false
      this.activeUserDetailsModal = true
      this.choosingPeopleType = roleType
    },

    closePersonDetails() {
      console.log('closePersonDetails')
      this.activeUserDetailsModal = false
      this.selectedUser = {}
      if (this.activeUserDetailsRefresh) {
        this.$emit('refresh-data')
      }
      this.choosingPeopleType = null
      this.activeUserDetailsRefresh = false
    },

    selectedappointmentManagers(selection) {
      // Payload necessary: {"space": { userKey: [role] } }
      console.log('SELECTION', selection)
      const users = {}
      selection.forEach(user => {
        users[user.key] = [this.choosingPeopleType]
      })
      this.$emit('update-users', {
        type: this.choosingPeopleType,
        usersAndRoles: users,
        refresh: true,
      })
    },

    /*selectedQueuesManagers({ users, queues, refresh }) {
      // Payload necessary: {"queues": { queueKey: { userKey: [role] } } }
      const managers = {}
      queues.forEach(queueKey => {
        managers[queueKey] = {}
        users.forEach(userKey => {
          const roles = []
          if (this.choosingPeopleType == 'queuesAdmins') {
            if (userKey in this.queuesWorkers && this.queuesWorkers[userKey].includes(queueKey)) {
              managers[queueKey][userKey] = ['queue-worker']
            }
            roles.push('queue-admin')
          } else if (this.choosingPeopleType == 'queuesWorkers') {
            if (userKey in this.queuesAdmins && this.queuesAdmins[userKey].includes(queueKey)) {
              managers[queueKey][userKey] = ['queue-admin']
            }
            roles.push('queue-worker')
          }

          if (queueKey in managers[queueKey]) {
            managers[queueKey][userKey] = managers[queueKey][userKey].concat(roles)
          } else {
            managers[queueKey][userKey] = roles
          }
        })
      })

      this.$emit('update-managers', {
        type: this.choosingPeopleType,
        usersAndRoles: { queues: managers },
        refresh: refresh,
      })

      this.closeModal()
    },*/

    deleteManager(userKey, role) {
      this.$buefy.dialog.confirm({
        confirmText: 'Remover',
        type: 'is-danger',
        cancelText: 'Cancelar',
        title: `Remover ${role === 'manager' ? 'Gestor' : role === 'viewer' ? 'Visualizador' : 'Operador'}`,
        message: `Tem a certeza que deseja remover as permissões deste(a) utilizador(a) neste procedimento?`,
        onConfirm: () => {
          // TODO: clean this 💀
          console.log('deleteManager :>> ', userKey, role)
          let userRoles = {}
          userRoles = { [userKey]: [role] }
          this.$emit('delete-users', {
            type: role,
            usersAndRoles: userRoles,
            refresh: true,
          })
        },
      })
    },

    closeModal() {
      this.choosingPeopleType = null
    },
  },
}
</script>

<i18n>
{
  "pt": {},
  "en": {}
}
</i18n>
