<template>
  <div v-if="appointment" class="relative">
    <fw-panel :title="'Horários'" featured :loading="savingData" after-loading-checked>
      <template v-if="slotsAreEmpty" #toolbar>
        <div class="flex gap-1 items-center">
          <div v-if="canEdit && $v.$error" class="p-1 mx-5 text-red-500 flex items-center gap-1 text-sm font-medium">
            <fw-icon-error-warning class="w-6 h-6"></fw-icon-error-warning>
            <span class="hidden lg:block">{{ $t('thereAreErrors') }}</span>
          </div>
          <div v-if="!timeSlotsAreEmpty && slotsAreEmpty" class="flex gap-5">
            <fw-button type="primary" @click.native="saveSlots()">{{ $t('save') }}</fw-button>
          </div>
        </div>
      </template>
    </fw-panel>

    <LoadingPlaceholder v-if="loading" />

    <div v-else>
      <fw-panel :title="'Horários disponíveis'" class="my-5">
        <template #toolbar>
          <fw-button v-if="slotsAreEmpty" type="link" @click.native="isActiveSlotsModal = true">Gerar</fw-button>
          <b-dropdown v-if="!slotsAreEmpty" aria-role="list" position="is-bottom-left">
            <fw-button-dropdown
              slot="trigger"
              aria-role="listitem"
              type="xlight"
              :label="filters[selectedFilter].label"
              class="font-medium text-gray-500"
            >
            </fw-button-dropdown>
            <b-dropdown-item
              v-for="(value, key) in filters"
              :key="key"
              :label="value.label"
              aria-role="listitem"
              :class="`font-medium ${key == selectedFilter ? 'text-primary' : 'text-gray-500'}`"
              @click.native="selectFilter(key)"
            >
              {{ value.label }}
            </b-dropdown-item>
          </b-dropdown>
        </template>

        <div v-if="!filteredSlotsAreEmpty" class="grid grid-cols-1 gap-3">
          <div
            v-for="(daySlots, day) in filteredSlots"
            :key="day"
            class="bg-white rounded-xl px-4 py-3 flex flex-col gap-2"
          >
            <div class="inline-flex items-center">
              <div class="font-semibold flex-1">
                <div class="text-lg">{{ day | formatDate }}</div>
                <div class="text-sm text-gray-500">
                  {{ $t(`weekdays.${getWeekDay(day)}`) }}
                </div>
              </div>

              <b-dropdown
                v-if="canEdit && !daySlots.some(el => !el.can_delete)"
                aria-role="list"
                position="is-bottom-left"
                append-to-body
              >
                <fw-button-dropdown
                  slot="trigger"
                  :chevron="false"
                  type="simple"
                  class="flex flex-col"
                  size="xs"
                  label="Ações"
                  custom-class="px-0.5"
                >
                  <fw-icon-more class="w-5 h-5" />
                </fw-button-dropdown>
                <b-dropdown-item custom aria-role="menuitem" class="paddingless">
                  <fw-button type="basic-action" expanded @click.native="deleteSlot(day)">Remover dia</fw-button>
                </b-dropdown-item>
              </b-dropdown>
            </div>

            <div
              v-for="(slot, slotI) in daySlots"
              :key="slotI"
              class="rounded-lg px-3 py-2 bg-gray-100 hover:bg-gray-200 flex flex-col"
            >
              <div class="flex gap-2 items-center">
                <div class="w-40">
                  <div class="flex gap-1 items-center font-semibold">
                    <fw-icon-clock-line class="h-5 w-5 flex-shrink-0"></fw-icon-clock-line>
                    <span>{{ slot.start_datetime | formatTime }} - {{ slot.end_datetime | formatTime }}</span>
                  </div>
                </div>
                <div class="flex-1">
                  <div
                    class="text-sm font-medium"
                    :class="{
                      'text-primary': !slot.reservation && slot.current_available > 0,
                      'text-gray-500': !slot.reservation && !slot.current_available,
                    }"
                  >
                    {{ slot.reservation ? 'Reservado' : slot.current_available > 0 ? 'Disponível' : 'Não disponível' }}
                  </div>
                </div>
                <small>{{ slot.availability }} total de vaga{{ slot.availability > 1 ? 's' : '' }}</small>
                <div>
                  <fw-menu-more v-if="canEdit" append-to-body>
                    <b-dropdown-item paddingless>
                      <fw-button :type="'dropdown-item'" expanded @click.native="editSlot(slot, day, slotI)">
                        Editar
                      </fw-button>
                    </b-dropdown-item>

                    <b-dropdown-item v-if="canEdit && slot.can_delete" paddingless>
                      <fw-button :type="'dropdown-item'" expanded @click.native="deleteSlot(day, slotI)">
                        Remover
                      </fw-button>
                    </b-dropdown-item>
                  </fw-menu-more>
                </div>
              </div>

              <div v-if="slot.reservations?.length" class="flex items-center gap-4 mt-2">
                <div v-for="reservation in slot.reservations" :key="reservation.key">
                  <fw-button
                    v-if="!isWorker"
                    type="link"
                    paddingless
                    @click.native="
                      $router.push({
                        name: 'manage-appointment-reservation',
                        params: {
                          key: appointment?.key,
                          reservationKey: reservation?.key,
                        },
                      })
                    "
                  >
                    <Person class="text-gray-700" :person="users[reservation.user_key]" />
                  </fw-button>
                  <fw-avatar v-else :user="users[reservation.user_key]" class="my-2" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <fw-panel-info v-else clean>Ainda não foram definidos slots para esta chamada.</fw-panel-info>
      </fw-panel>
    </div>

    <fw-modal size="3xl" width="42rem" :active.sync="isActiveSlotsModal" @close="isActiveSlotsModal = false">
      <ModalTimeSlots @create="createTimeSlots" @close="isActiveSlotsModal = false"></ModalTimeSlots>
    </fw-modal>

    <fw-modal size="3xl" width="42rem" :active.sync="isActiveEditSlotsModal" @close="isActiveEditSlotsModal = false">
      <ModalEditTimeSlots
        :time-slot="selectedSlot"
        @update="updateSlot"
        @close="isActiveEditSlotsModal = false"
      ></ModalEditTimeSlots>
    </fw-modal>

    <fw-panel-info debug label="Data (raw)">
      <json-viewer :value="{ appointment, timeSlots, slots, selectedSlot, peopleValidations, isWorker }"></json-viewer>
    </fw-panel-info>
  </div>
</template>

<script>
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'
import ModalEditTimeSlots from '@/components/modals/ModalEditTimeSlots'
import ModalTimeSlots from '@/components/modals/ModalTimeSlots'
import { required, minValue } from 'vuelidate/lib/validators'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import { CALL_TYPES } from '@/utils/index.js'
import groupBy from 'lodash/groupBy'

export default {
  components: {
    LoadingPlaceholder,
    ModalTimeSlots,
    ModalEditTimeSlots,
    Person,
  },

  props: {
    appointment: {
      type: Object,
      default: () => {
        return {}
      },
    },

    slots: {
      type: Object,
      default: () => {},
    },

    users: {
      type: Object,
      default: () => {},
    },

    peopleValidations: {
      type: Object,
      default: () => {},
    },

    canEdit: {
      type: Boolean,
      default: true,
    },

    savingData: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isActiveEditSlotsModal: false,
      isActiveSlotsModal: false,
      timeSlots: {},
      selectedSlot: {},
      isModalActive: false,
      appointmentTypes: CALL_TYPES,
      minEndDate: Dates.now()
        .subtract(1, 'day')
        .toDate(),
      selectedFilter: 'all',
      filters: {
        all: {
          label: 'Todos',
        },
        noReservations: {
          label: 'Sem agendamentos',
        },
        withReservations: {
          label: 'Com agendamentos',
        },
      },
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    language() {
      return this.$store.state.language
    },

    user() {
      return this.$store.getters.getUser
    },

    secondaryLanguage() {
      return this.language === 'pt' ? 'en' : 'pt'
    },

    langs() {
      return [this.language, this.secondaryLanguage]
    },

    minDate() {
      return this.appointment && this.appointment.state === 'published' ? null : new Date()
    },

    timeSlotsAreEmpty() {
      return utils.isObjectEmpty(this.timeSlots)
    },

    filteredSlotsAreEmpty() {
      return utils.isObjectEmpty(this.filteredSlots)
    },

    slotsAreEmpty() {
      return utils.isObjectEmpty(this.slots)
    },

    isWorker() {
      return (
        this.peopleValidations && !this.peopleValidations.can_edit_managers && !this.peopleValidations.can_edit_workers
      )
    },

    filteredSlots() {
      if (this.selectedFilter === 'noReservations') {
        const slots = Object.values(this.timeSlots)
          .flat()
          .filter(el => !el.reservations?.length)

        return groupBy(slots, x => Dates.buildCore(x.start_datetime).format('YYYY-MM-DD'))
      }

      if (this.selectedFilter === 'withReservations') {
        const slots = Object.values(this.timeSlots)
          .flat()
          .filter(el => el.reservations?.length)

        return groupBy(slots, x => Dates.buildCore(x.start_datetime).format('YYYY-MM-DD'))
      }

      return this.timeSlots
    },
  },

  watch: {
    slots(newVal) {
      this.timeSlots = newVal
    },
  },

  mounted() {
    this.timeSlots = this.slots
  },

  validations() {
    return {
      appointment: {
        start_date: { required, min: minValue(this.minDate) },
        end_date: { required, min: minValue(this.start_date || this.minDate) },
      },
    }
  },

  methods: {
    saveSlots() {
      this.$emit('save-slots', { slots: Object.values(this.timeSlots).flat() })
    },

    selectFilter(key) {
      this.selectedFilter = key
    },

    getWeekDay(date) {
      return Dates.buildCore(date).day()
    },

    createTimeSlots(params) {
      const { startTime, endTime, startDate, endDate, timeInterval, availability } = params

      let startingDate = Dates.buildCore(startDate).startOf('hour') // now, but with 0 mins, 0 secs, and 0 ms
      const endingDate = Dates.buildCore(endDate).endOf('hour')
      const timeSlots = {}

      let startTimeSlot = undefined
      let endTimeSlot = undefined

      const dates = Dates.getDatesFromRange(startingDate, endingDate)

      for (const date of dates) {
        startTimeSlot = Dates.buildCore(date)
          .set('hour', startTime.getHours())
          .set('minute', startTime.getMinutes())
          .set('second', 0)

        endTimeSlot = Dates.buildCore(date)
          .set('hour', endTime.getHours())
          .set('minute', endTime.getMinutes())
          .set('second', 0)

        const currentWeekDay = startTimeSlot.isoWeekday()
        if (currentWeekDay == 7 || currentWeekDay == 6) continue

        while (startTimeSlot.isBefore(endTimeSlot, 'minute') || startTimeSlot.isSame(endTimeSlot, 'minute')) {
          let newTime = startTimeSlot.add(timeInterval, 'minute')
          if (newTime.isAfter(endTimeSlot, 'minute')) break

          const data = {
            start_datetime: startTimeSlot.format('YYYY-MM-DD HH:mm:ss'),
            end_datetime: newTime.format('YYYY-MM-DD HH:mm:ss'),
            availability,
            can_delete: true,
          }
          if (timeSlots[date]) timeSlots[date].push(data)
          else timeSlots[date] = [data]
          startTimeSlot = newTime
        }
      }

      this.timeSlots = { ...this.timeSlots, ...timeSlots }
    },

    editSlot(slot, slotDate, slotI) {
      console.log({ slotDate, slotI })
      this.selectedSlot = { ...slot, slotDate, slotI }
      this.isActiveEditSlotsModal = true
    },

    updateSlot(availability) {
      console.log('this.selectedSlot', this.selectedSlot)
      if (this.selectedSlot.key) this.$emit('update-slot', { key: this.selectedSlot.key, availability })

      const slot = this.timeSlots[this.selectedSlot.slotDate][this.selectedSlot.slotI]
      if (slot) slot.availability = availability
      this.isActiveEditSlotsModal = false
      this.selectedSlot = null
    },

    deleteSlot(slotDate, slotIndex = -1) {
      if (slotIndex > -1) {
        const slot = this.timeSlots[slotDate][slotIndex]
        console.log('slot', slot)
        this.$emit('delete-slots', slot?.key ? [slot.key] : undefined)
        this.timeSlots[slotDate].splice(slotIndex, 1)
      } else {
        this.$emit(
          'delete-slots',
          this.timeSlots[slotDate].filter(el => el.key).map(el => el.key)
        )
        this.$delete(this.timeSlots, slotDate)
      }
    },
  },
}
</script>
<i18n>
{
  "pt": {
    "appointment": "Metadados",
    "thereAreErrors": "Existem erros no formulário",
    "close": "Fechar",
    "edit": "Editar",
    "save": "Guardar",
    "delete": "Remover",
    "generalDetails": "Detalhes gerais",
    "appointmentCode": "Código do procedimento",
    "date": {
      "label": "Data",
      "placeholder": "Escolha uma data"
    },
    "startDate": {
      "label": "Data de início",
      "min": "Escolha uma data mais recente",
      "required": "Insira uma data de início"
    },
    "endDate": {
      "label": "Data de fim",
      "min": "Escolha uma data a seguir a data de início",
      "required": "Insira uma data de fim"
    },
    "today": "Hoje",
    "yesterday": "Ontem",
    "weekdays": {
      "0": "Domingo",
      "1": "Segunda-feira",
      "2": "Terça-feira",
      "3": "Quarta-feira",
      "4": "Quinta-feira",
      "5": "Sexta-feira",
      "6": "Sábado"
    },
    "notDefined": "Não definido"
  },
  "en": {
    "generalDetails": "General details",
    "appointmentCode": "Procedure code",
    "appointment": "Metadata",
    "thereAreErrors": "There are errors in the form",
    "close": "Close",
    "edit": "Edit",
    "save": "Save",
    "delete": "Remove",
    "date": {
      "label": "Date",
      "placeholder": "Choose a date"
    },
    "startDate": {
      "label": "Start date",
      "min": "Choose a more recent date",
      "required": "Enter a start date"
    },
    "endDate": {
      "label": "End Date",
      "min": "Choose a date after the start date",
      "required": "Enter an end date"
    },
    "today": "Today",
    "yesterday": "Yesterday",
    "weekdays": {
      "0": "Sunday",
      "1": "Monday",
      "2": "Tuesday",
      "3": "Wednesday",
      "4": "Thursday",
      "5": "Friday",
      "6": "Saturday"
    },
    "notDefined": "Not defined"
  }
}
</i18n>
