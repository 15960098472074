<template>
  <section>
    <fw-heading size="xl">Configurações</fw-heading>

    <PanelManageAppointmentPeople
      :appointment="appointment"
      :users="users"
      :loading="loading"
      :validations="peopleValidations"
      :managers-keys="managers"
      :workers-keys="workers"
      :viewer-keys="viewers"
      @update-users="$emit('update-users', $event)"
      @delete-users="$emit('delete-users', $event)"
    ></PanelManageAppointmentPeople>

    <LoadingPlaceholder v-if="loading" />
    <fw-panel v-else :title="'Calendarização'" boxed="lg" class="my-5" custom-class="bg-white flex flex-col gap-3">
      <template #toolbar>
        <div class="flex gap-1 items-center">
          <div
            v-if="appointment.state == 'draft' && $v.$error"
            class="p-1 mx-5 text-red-500 flex items-center gap-1 text-sm font-medium"
          >
            <fw-icon-error-warning class="w-6 h-6"></fw-icon-error-warning>
            <span class="hidden lg:block">{{ $t('thereAreErrors') }}</span>
          </div>
          <div v-if="appointment.state == 'draft'" class="flex gap-5">
            <fw-button type="primary" @click.native="saveProc()">{{ $t('save') }}</fw-button>
          </div>
        </div>
      </template>

      <div v-if="appointment?.key" class="flex flex-row gap-5">
        <div class="sm:w-1/2">
          <span class="font-semibold text-gray-500 mb-1 text-xs">{{ $t('endDate.label') }}</span>
          <div v-if="!appointment.state == 'draft'" class="font-medium">
            {{ appointment.reservations_end_date | formatDate }}
          </div>
          <b-datepicker
            v-else
            v-model="appointment.reservations_end_date"
            :class="{
              error: $v.appointment.reservations_end_date.$error,
            }"
            :placeholder="$t('date.placeholder')"
            icon="calendar-alt"
            trap-focus
            :min-date="minEndDate"
            locale="pt-PT"
            position="is-bottom-left"
            :disabled="appointment.state != 'draft'"
            @icon-right-click="appointment.reservations_end_date = null"
          >
          </b-datepicker>

          <fw-tip v-if="$v.appointment.reservations_end_date.$error" error>
            <span v-if="!$v.appointment.reservations_end_date.min">{{ $t('endDate.min') }}</span>
            <span v-else>{{ $t('endDate.required') }}</span>
          </fw-tip>
        </div>
      </div>
    </fw-panel>

    <fw-panel-box type="orange" class="px-2 py-3 my-3 font-medium">
      <div class="flex gap-3">
        <div>
          <fw-icon-eye class="w-8 h-8 flex-shrink-0 opacity-80" />
        </div>
        <div class="flex flex-col gap-1">
          <div class="text-sm flex flex-col gap-3">
            <div>
              A consulta dos agendamentos efetuados e horários disponíveis, a definição de presença ou ausência e a
              inserção e edição de comentários, estará disponível através do link indicado, para utilizadores não
              autenticados. Os códigos disponibilizados serão válidos por um período de 14 dias. Gerando um novo código
              inviabilizará o acesso com recurso ao código anterior.
            </div>
            <div
              v-if="appointment.public_token"
              class="flex gap-3 flex-1 items-center bg-white bg-opacity-50 rounded-lg"
            >
              <div class="flex-1 relative">
                <input type="text" :value="appointmentPublicUrlWithToken" class="px-3 py-1.5 bg-transparent w-full" />
                <div class="w-12 h-full bg-gradient-to-l from-[#FAF7F2] to-transparent absolute top-0 right-0"></div>
              </div>
              <div>
                <fw-button
                  :icon="urlCopiedToClipboard ? 'check' : 'copy'"
                  :type="'link'"
                  @click.native="toClipboardUrlWithToken"
                >
                  Copiar</fw-button
                >
              </div>
            </div>
          </div>
          <div v-if="appointment.public_token" class="flex gap-3 justify-between items-center">
            <div v-if="appointment.public_token_expiration_date" class="text-sm text-gray-500">
              O código de acesso irá expirar em {{ appointment.public_token_expiration_date | humanDateTimePT }}
            </div>
            <div v-else class="text-sm text-gray-500">
              Sem data de expiração
            </div>
            <div>
              <fw-button v-if="$store.getters.isLoggedIn" type="link" @click.native="$emit('reset-token')"
                >Gerar novo código</fw-button
              >
            </div>
          </div>
          <div v-else class="flex justify-center items-center">
            <fw-button v-if="$store.getters.isLoggedIn" type="link" @click.native="$emit('reset-token')"
              >Gerar novo código</fw-button
            >
          </div>
        </div>
      </div>
    </fw-panel-box>

    <fw-panel-info debug label="Data (raw)">
      <json-viewer :value="{ appointment, validations, minEndDate }"></json-viewer>
    </fw-panel-info>
  </section>
</template>

<script>
import PanelManageAppointmentPeople from '@/components/panels/manage/occupationalMedicine/PanelManageAppointmentPeople'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import { required, minValue } from 'vuelidate/lib/validators'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

export default {
  components: {
    PanelManageAppointmentPeople,
    LoadingPlaceholder,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    savingData: {
      type: Boolean,
      default: false,
    },
    appointment: {
      type: Object,
      default: () => {},
    },
    peopleValidations: {
      type: Object,
      default: () => {
        return {
          can_edit: false,
        }
      },
    },
    validations: {
      type: Object,
      default: () => {
        return {
          can_edit: false,
        }
      },
    },
    users: {
      type: Object,
      default: () => {},
    },
    managers: {
      type: Array,
      default: () => [],
    },
    workers: {
      type: Array,
      default: () => [],
    },
    viewers: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      minEndDate: Dates.now()
        .add(1, 'day')
        .toDate(),
      reservationsEndDate: null,
      urlCopiedToClipboard: false,
    }
  },

  computed: {
    appointmentPublicUrlWithToken() {
      return `${
        process.env.VUE_APP_IS_PRODUCTION
          ? 'https://ucsocialsupport.uc.pt'
          : process.env.VUE_APP_IS_STAGING
          ? 'https://ucsocialsupport.staging.ucframework.pt'
          : 'https://ucsocialsupport.dev.ucframework.pt'
      }/view/t/${this.appointment.public_token}`
    },
    api() {
      return this.$store.state.api.base
    },
    language() {
      return this.$store.state.language
    },
    secondaryLanguage() {
      return this.language === 'pt' ? 'en' : 'pt'
    },
    langs() {
      return [this.language, this.secondaryLanguage]
    },
  },

  validations() {
    return {
      appointment: {
        reservations_end_date: { required, min: minValue(this.minEndDate) },
      },
    }
  },

  methods: {
    toClipboardUrlWithToken() {
      if (this.appointmentPublicUrlWithToken === null) return
      this.$copyText(this.appointmentPublicUrlWithToken).then(() => {
        this.urlCopiedToClipboard = true

        if (this.clipboard_timer !== null) {
          clearTimeout(this.clipboard_timer)
        } else {
          this.$buefy.toast.open({
            duration: 3000,
            message: `Link copiado para a área de transferência.`,
            position: 'is-top',
          })
        }
        this.clipboard_timer = setTimeout(() => {
          this.urlCopiedToClipboard = false
          this.clipboard_timer = null
        }, 3000)
      })
    },
    saveProc() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit('save-appointment', {
          reservations_end_date: Dates.formatDateToAPI(this.appointment.reservations_end_date),
        })
      }
    },
  },
}
</script>
<i18n>
{
  "pt": {
    "appointment": "Metadados",
    "thereAreErrors": "Existem erros no formulário",
    "close": "Fechar",
    "edit": "Editar",
    "save": "Guardar",
    "delete": "Remover",
    "generalDetails": "Detalhes gerais",
    "appointmentCode": "Código do procedimento",
    "date": {
      "label": "Data",
      "placeholder": "Escolha uma data"
    },
    "startDate": {
      "label": "Data de início",
      "min": "Escolha uma data mais recente",
      "required": "Insira uma data de início"
    },
    "endDate": {
      "label": "Data de fim de agendamentos",
      "min": "Escolha uma data a seguir a data de início",
      "required": "Insira uma data de fim"
    },
    "title": {
      "label": "Título",
      "placeholder": {
        "pt": "Título em Português",
        "en": "Título em Inglês"
      },
      "required": "Insira um título em Portugês e Inglês."
    },
    "description": {
      "label": "Descrição",
      "placeholder": {
        "pt": "Descrição em Português",
        "en": "Descrição em Inglês"
      },
      "required": "Insira uma descrição em Portugês e Inglês."
    },
    "charsLimit": "Limite de {limit} caracteres.",
    "procType": {
      "label": "Tipo",
      "placeholder": "Escolha um tipo",
      "scholarship": "Scholarship",
      "occupational_medicine": "Medicina do Trabalho",
      "required": "Escolha o tipo do procedimento."
    },
    "notDefined": "Não definido"
  },
  "en": {
    "generalDetails": "General details",
    "appointmentCode": "Procedure code",
    "appointment": "Metadata",
    "thereAreErrors": "There are errors in the form",
    "close": "Close",
    "edit": "Edit",
    "save": "Save",
    "delete": "Remove",
    "date": {
      "label": "Date",
      "placeholder": "Choose a date"
    },
    "startDate": {
      "label": "Start date",
      "min": "Choose a more recent date",
      "required": "Enter a start date"
    },
    "endDate": {
      "label": "Reservations end Date",
      "min": "Choose a date after the start date",
      "required": "Enter an end date"
    },
    "title": {
      "label": "Title",
      "placeholder": {
        "pt": "Title in Portuguese",
        "en": "English title"
      },
      "required": "Enter a title in English and Portuguese."
    },
    "description": {
      "label": "Description",
      "placeholder": {
        "pt": "Description in Portuguese",
        "en": "Description in English"
      },
      "required": "Enter a description in English and Portuguese."
    },
    "charsLimit": "Limit of {limit} characters.",
    "procType": {
      "label": "Tipo",
      "placeholder": "Escolha um tipo",
      "scholarship": "Scholarship",
      "occupational_medicine": "Occupational Medicine",
      "required": "Enter the appointment type."
    },
    "notDefined": "Not defined"
  }
}
</i18n>
