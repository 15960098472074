<template>
  <div>
    <DashboardHero
      :title="appointment.prefix + '' + appointment.code"
      class="mt-2 mb-5"
      type="cover"
      background="abstract-02"
    >
      <template #toolbar>
        <div v-if="appointment.state == 'published'" class="px-3 py-1 rounded-full bg-primary bg-opacity-90">
          <div class="inline-flex flex-shrink-0 text-white relative gap-2">
            <div class="font-semibold">Chamada aberta</div>
            <fw-dot color="white" inline class="mt-1" />
          </div>
        </div>
        <div v-else-if="appointment.state == 'closed'">
          <div class="text-sm opacity-70 font-medium">
            Chamada terminada
          </div>
        </div>
      </template>

      <template #footer>
        <div>
          <fw-label color="text-white opacity-50">{{ $t('createdAt') }}</fw-label>
          <div>{{ appointment.created_date | formatDateTime }}</div>
        </div>
        <div v-if="appointment.state === 'published'">
          <fw-label color="text-white opacity-50">{{ $t('publishedAt') }}</fw-label>
          <div>{{ appointment.published_date | formatDate }}</div>
        </div>
        <div v-if="appointment.state === 'closed'">
          <fw-label color="text-white opacity-50">{{ $t('closedAt') }}</fw-label>
          <div>{{ appointment.closed_date | formatDate }}</div>
        </div>
      </template>
    </DashboardHero>

    <fw-panel :title="$t('statusMainBoardTitle')" class="my-5">
      <div class="flex flex-col gap-4">
        <div class="flex gap-3 text-gray-500">
          <template v-for="(status, key) in appointmentStatus">
            <div
              :key="key"
              :class="{
                'w-2/5 border-primary min-h-28': status.isRunning,
                'w-1/3 border-white min-h-28': !status.isRunning,
                'text-primary': status.isActive,
              }"
              class="relative rounded-xl bg-white border p-4 flex flex-col text-center font-semibold items-center gap-1 group"
            >
              <div class="w-full flex items-center justify-center">
                <div v-if="status.isDone">
                  <fw-icon-check-solid class="w-6 h-6 flex-shrink-0" />
                </div>
                <div v-else-if="status.isRunning">
                  <fw-icon-run class="w-6 h-6 flex-shrink-0 text-primary animate-pulse" />
                </div>
                <div v-else>
                  <fw-icon-rest class="w-6 h-6 flex-shrink-0 text-gray-300" />
                </div>
              </div>
              <div
                class="flex-1"
                :class="{
                  'font-bold': status.isActive || status.isDone,
                }"
              >
                {{ status.label }}
              </div>
              <div class="flex justify-center items-center font-normal text-xs">
                <div v-if="status.isRunning" class="text-primary">
                  {{ $t('phaseOngoing') }}
                </div>
                <div v-else-if="status.isDone && key !== 'closed'">
                  {{ $t('phaseFinished') }}
                </div>
                <div v-else-if="!status.isDone && !status.isActive">
                  {{ $t('phasePending') }}
                </div>
                <div v-else-if="key === 'closed' && status.isDone">
                  {{ $t('phaseClosed') }}
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </fw-panel>
  </div>
</template>

<script>
import DashboardHero from '@/fw-modules/fw-core-vue/ui/components/dashboard/DashboardHero'

export default {
  components: {
    DashboardHero,
  },

  props: {
    appointment: {
      type: Object,
      default: () => {},
    },
    checks: {
      type: Object,
      default: () => {},
    },
    users: {
      type: Object,
      default: () => {},
    },
    user: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },

  computed: {
    language() {
      return this.$store.state.language
    },
    secondaryLanguage() {
      return this.language === 'pt' ? 'en' : 'pt'
    },

    appointmentStatus() {
      if (!this.appointment.stats) return {}
      return {
        draft: {
          label: this.$t('appointmentStatus.draft'),
          canChange: false,
          isActive: this.appointment.state == 'draft',
          isRunning: this.appointment.state == 'draft',
          isDone: this.appointment.state !== 'draft',
        },
        published: {
          label: this.$t('appointmentStatus.publishedAndOpen'),
          canChange: false,
          isActive: this.appointment.state === 'published',
          isRunning: this.appointment.state === 'published',
          isDone: this.appointment.state === 'closed',
        },
        closed: {
          label: this.$t('appointmentStatus.closed'),
          canChange: false,
          isActive: this.appointment.state == 'closed',
          isRunning: false,
          isDone: this.appointment.state == 'closed',
        },
      }
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "statusMainBoardTitle": "Estado",
    "appointmentKey": "Chave do procedimento",
    "createdAt": "Criado em",
    "creator": "Criador",
    "notDefined": "Não definido",
    "title": "Titulo",
    "appointmentType": {
      "scholarship": "Apoio",
      "occupational_medicine": "Medicina do Trabalho"
    },
    "appointmentStatus": {
      "draft": "Rascunho",
      "publishedAndOpen": "Agendamentos em aberto",
      "running": "Análise de candidaturas",
      "closed": "Encerrado"
    },
    "phaseFinished": "Terminado",
    "phaseOngoing": "A decorrer",
    "phasePending": "Pendente",
    "phaseClosed": "Terminado",
    "startsAt": "Inicio de candidaturas",
    "endsAt": "Fim de candidaturas",
    "publishedAt": "Publicado em",
    "closedAt": "Fechado em",
    "people": "Pessoas",
    "submittedReservations": "Submetidas",
    "candidates": "Candidaturas"
  },
  "en": {
    "statusMainBoardTitle": "Estado",
    "appointmentKey": "Chave do procedimento",
    "createdAt": "Criado em",
    "creator": "Criador",
    "notDefined": "Não definido",
    "title": "Titulo",
    "appointmentType": {
      "scholarship": "Apoio",
      "occupational_medicine": "Occupational Medicine"
    },
    "appointmentStatus": {
      "draft": "Rascunho",
      "publishedAndOpen": "Agendamentos em aberto",
      "running": "Análise de candidaturas",
      "closed": "Encerrado"
    },
    "phaseFinished": "Terminado",
    "phaseOngoing": "A decorrer",
    "phasePending": "Pendente",
    "startsAt": "Inicio de candidaturas",
    "endsAt": "Fim de candidaturas",
    "publishedAt": "Publicado em",
    "closedAt": "Fechado em",
    "people": "Pessoas",
    "phaseClosed": "Terminado",
    "submittedReservations": "Submitted",
    "candidates": "Reservations"
  }
}
</i18n>
