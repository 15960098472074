<template>
  <div
    v-if="timeSlot"
    class="rounded-lg px-3 py-2 bg-gray-100 flex flex-col"
    :class="{
      'border border-gray-200 text-primary hover:bg-gray-200': selected,
      'text-white bg-primary hover:bg-gray-primary': active,
      'hover:bg-gray-200': !active,
    }"
    @click="$emit('click')"
  >
    <div class="flex gap-2 items-center">
      <div class="w-24">
        <div class="flex gap-1 items-center font-semibold">
          <fw-icon-clock-line class="h-5 w-5 flex-shrink-0"></fw-icon-clock-line>
          <span>{{ timeSlot.start_datetime | formatTime }}</span>
        </div>
      </div>
      <div class="flex-1 text-sm font-medium">
        <slot>
          <div v-if="active">Reservado por si</div>
          <div v-else-if="!timeSlot.is_time_available" class="flex items-center justify-between ">
            <span>Não disponível</span>
            <fw-icon-lock class="h-4 w-4"></fw-icon-lock>
          </div>
          <div v-else :class="{ 'text-primary': timeSlot.can_select }">
            {{ timeSlot.can_select ? 'Disponível' : 'Reservado' }}
          </div>
        </slot>
      </div>
      <div v-if="showAvailability">
        <small v-if="timeSlot.current_available > 0">
          {{ timeSlot.current_available }} vaga{{ timeSlot.current_available > 1 ? 's' : '' }}</small
        >
      </div>
      <div v-if="showActions">
        <fw-icon-checkbox-circle v-if="selected" class="h-5 w-5" />
        <fw-button v-else-if="timeSlot.can_select" @click.native="$emit('select', timeSlot.key)">
          <span :class="{ 'text-primary': timeSlot.can_select }">Reservar</span>
        </fw-button>
      </div>
    </div>
    <slot name="secondline"></slot>
  </div>
</template>

<script>
export default {
  props: {
    timeSlot: {
      type: Object,
      default: () => {},
    },

    selected: {
      type: Boolean,
      default: false,
    },

    showActions: {
      type: Boolean,
      default: true,
    },

    active: {
      type: Boolean,
      default: false,
    },

    showAvailability: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
