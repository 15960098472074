<template>
  <div>
    <fw-heading size="xl">Trabalhadores</fw-heading>

    <LoadingPlaceholder v-if="loading" />

    <fw-panel
      v-else
      title="Trabalhadores a convocar"
      boxed="xs"
      custom-class="bg-white"
      class="my-5"
      :counter="applicants.length"
    >
      <template #toolbar>
        <b-dropdown v-if="canEdit" aria-role="list" position="is-bottom-left">
          <fw-button-dropdown slot="trigger" :chevron="false" type="simple" label="Ações">
            <fw-icon-more class="w-5 h-5" />
          </fw-button-dropdown>
          <b-dropdown-item custom aria-role="menuitem" class="paddingless">
            <fw-button type="basic-action" expanded @click.native="isActiveImporUsersModal = true"
              >Importar lista de pessoas</fw-button
            >
          </b-dropdown-item>
          <b-dropdown-item v-if="canResend" custom aria-role="menuitem" class="paddingless">
            <fw-button type="basic-action" expanded @click.native="$emit('resend', undefined)"
              >Enviar convites</fw-button
            >
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <ExpandList
        add-title="Adicionar"
        :empty="!applicants || applicants.length < 1"
        :show-add-items="canEdit"
        @add-element="choosePeople"
      >
        <template #list>
          <Person
            v-for="(user, index) in applicants"
            :key="user.key"
            :class="{
              'border-b border-gray-100': index < applicants.length - 1,
            }"
            :open-details-on-click="canEdit"
            :person="user"
            :selectable="false"
            :clickable="canEdit"
          >
            <template v-if="canEdit" #options>
              <div v-if="canResend">
                <small v-if="user.invite_sent_date">Enviado em {{ user.invite_sent_date | formatDateTime }}</small>
                <small v-else>Convocatória não enviada</small>
              </div>
              <b-dropdown aria-role="list" position="is-bottom-left">
                <fw-button-dropdown slot="trigger" :chevron="false" type="simple" label="Ações">
                  <fw-icon-more class="w-5 h-5" />
                </fw-button-dropdown>
                <b-dropdown-item v-if="user.can_delete" custom aria-role="menuitem" class="paddingless">
                  <fw-button type="basic-action" custom-class="w-full" @click.native="deleteApplicant(user.user_key)"
                    >Remover</fw-button
                  >
                </b-dropdown-item>
                <b-dropdown-item v-if="canResend" custom aria-role="menuitem" class="paddingless">
                  <fw-button type="basic-action" custom-class="w-full" @click.native="$emit('resend', [user.user_key])"
                    >Reenviar</fw-button
                  >
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </Person>
          <div v-if="applicants.length == 0 && !canEdit" class="text-center text-gray-400 py-14 text-sm">
            Sem trabalhadores associados.
          </div>
        </template>
      </ExpandList>
    </fw-panel>

    <b-modal
      :active="activeChoosePeopleModal"
      scroll="keep"
      :can-cancel="true"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="700"
      :on-cancel="closeModal"
      :custom-class="'rounded-buefy-modal'"
    >
      <ChoosePeopleModal
        :title="'Adicionar trabalhador(es)'"
        :instructions="'Indique um(a) ou mais utilizadores(as) para adicionar neste procedimento.'"
        :multiselect="true"
        :not-alowed-users="excludeUsers"
        :invite-people="false"
        :endpoint="appointmentUsersSearch"
        @selected="selectedUsers"
        @close="closeModal"
      ></ChoosePeopleModal>
    </b-modal>

    <b-modal
      :active="activeUserDetailsModal"
      scroll="keep"
      :can-cancel="true"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="700"
      :on-cancel="closePersonDetails"
      :custom-class="'rounded-buefy-modal'"
    >
      <UserDetails v-if="activeUserDetailsModal" :user="selectedUser" @close="closePersonDetails" />
    </b-modal>

    <fw-modal :active.sync="isActiveImporUsersModal" boxed="xs" size="min" width="42rem" @close="closeModal">
      <ModalImportUsers
        v-if="isActiveImporUsersModal"
        :appointment="appointment"
        :exclude-users="excludeUsers"
        @selected="selectedUsers"
        @close="closeModal"
      ></ModalImportUsers>
    </fw-modal>
  </div>
</template>

<script>
import ExpandList from '@/fw-modules/fw-core-vue/ui/components/lists/ExpandList'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'
import ChoosePeopleModal from '@/fw-modules/fw-core-vue/ui/components/modals/ChoosePeopleModal'
import ModalImportUsers from '@/components/modals/ModalImportUsers'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import UserDetails from '@/components/modals/UserDetails'

export default {
  components: {
    ExpandList,
    Person,
    ChoosePeopleModal,
    LoadingPlaceholder,
    ModalImportUsers,
    UserDetails,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    savingData: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canResend: {
      type: Boolean,
      default: false,
    },
    appointment: {
      type: Object,
      default: () => {},
    },
    applicants: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      activeUserDetailsModal: false,
      activeUserDetailsRefresh: false,
      isActiveImporUsersModal: false,
      selectedUser: {},
      activeChoosePeopleModal: false,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    loggedUser() {
      return this.$store.getters.getUser
    },

    excludeUsers() {
      return this.applicants.map(el => el.user_key)
    },

    isOwner() {
      return this.appointment.user_key == this.loggedUser.key
    },
  },

  methods: {
    async appointmentUsersSearch(params) {
      return await this.api.searchAppointmentUsers(this.appointment.key, params)
    },

    choosePeople() {
      this.activeChoosePeopleModal = true
    },

    closePersonDetails() {
      console.log('closePersonDetails')
      this.activeUserDetailsModal = false
      this.selectedUser = {}
    },

    selectedUsers(selection) {
      console.log('SELECTION', selection)
      const users = selection.map(el => el.key).concat(this.applicants.map(el => el.user_key))
      console.log('users', users)
      this.$emit('save', users)
    },

    deleteApplicant(userKey) {
      this.$buefy.dialog.confirm({
        confirmText: 'Remover',
        type: 'is-danger',
        cancelText: 'Cancelar',
        title: `Remover utilizador`,
        message: `Tem a certeza que deseja remover este utilizador(a)?`,
        onConfirm: () => {
          const users = this.applicants.filter(el => el.user_key != userKey).map(el => el.user_key)
          this.$emit('save', users)
        },
      })
    },

    closeModal() {
      this.activeChoosePeopleModal = false
      this.isActiveImporUsersModal = false
    },
  },
}
</script>

<i18n>
{
  "pt": {},
  "en": {}
}
</i18n>
