<template>
  <fw-panel title="Gerar horários">
    <div class="my-3 grid sm:grid-cols-2 sm:gap-5">
      <div>
        <fw-label>{{ $t('startDate.label') }}</fw-label>

        <b-datepicker
          v-model="startDate"
          :placeholder="$t('date.placeholder')"
          icon="calendar-alt"
          locale="pt-PT"
          :min-date="minDate"
          trap-focus
          :class="{
            error: $v.startDate.$error,
          }"
          @icon-right-click="startDate = null"
        >
        </b-datepicker>
        <fw-tip v-if="$v.startDate.$error" error>
          <span v-if="!$v.startDate.min">{{ $t('startDate.min') }}</span>
          <span v-else>{{ $t('startDate.required') }}</span>
        </fw-tip>
      </div>

      <div>
        <fw-label>{{ $t('endDate.label') }}</fw-label>
        <b-datepicker
          v-model="endDate"
          :class="{
            error: $v.endDate.$error,
          }"
          :placeholder="$t('date.placeholder')"
          icon="calendar-alt"
          trap-focus
          :min-date="startDate ?? minDate"
          locale="pt-PT"
          position="is-bottom-left"
          @icon-right-click="endDate = null"
        >
        </b-datepicker>

        <fw-tip v-if="$v.endDate.$error" error>
          <span v-if="!$v.endDate.min">{{ $t('endDate.min') }}</span>
          <span v-else>{{ $t('endDate.required') }}</span>
        </fw-tip>
      </div>
    </div>

    <div class="my-3 grid sm:grid-cols-2 sm:gap-5">
      <div>
        <fw-label>{{ $t('startTime.label') }}</fw-label>

        <b-timepicker
          v-model="startTime"
          :placeholder="$t('time.placeholder')"
          editable
          icon-pack="fa"
          icon="clock"
          trap-focus
          locale="pt-PT"
          hour-format="24"
          :class="{
            error: $v.startTime.$error,
          }"
          @icon-right-click="startTime = null"
        >
        </b-timepicker>
        <fw-tip v-if="$v.startTime.$error" error>
          <span v-if="!$v.startTime.min">{{ $t('startTime.min') }}</span>
          <span v-else>{{ $t('startTime.required') }}</span>
        </fw-tip>
      </div>

      <div>
        <fw-label>{{ $t('endTime.label') }}</fw-label>
        <b-timepicker
          v-model="endTime"
          :class="{
            error: $v.endTime.$error,
          }"
          :placeholder="$t('time.placeholder')"
          :min-time="startTime"
          editable
          icon-pack="fa"
          icon="clock"
          trap-focus
          locale="pt-PT"
          hour-format="24"
          @icon-right-click="endTime = null"
        >
        </b-timepicker>

        <fw-tip v-if="$v.endTime.$error" error>
          <span v-if="!$v.endTime.min">{{ $t('endTime.min') }}</span>
          <span v-else>{{ $t('endTime.required') }}</span>
        </fw-tip>
      </div>
    </div>

    <div class="my-3 grid sm:grid-cols-2 sm:gap-5">
      <div>
        <fw-label>Intervalo de tempo</fw-label>
        <div>
          <NumberInput
            v-model="timeInterval"
            :min="5"
            :max="60"
            placeholder="Insira um intervalo de tempo"
            help="Intervalo de tempo em minutos"
          />
        </div>
        <fw-tip v-if="$v.timeInterval.$error" error>
          <span v-if="!$v.timeInterval.min">{{ $t('timeInterval.min') }}</span>
          <span v-else>{{ $t('timeInterval.required') }}</span>
        </fw-tip>
      </div>

      <div>
        <fw-label>Número de vagas por intervalo</fw-label>
        <div>
          <NumberInput v-model="availability" :min="1" :max="100" placeholder="Insira o número de vagas" />
        </div>
        <fw-tip v-if="$v.availability.$error" error>
          <span v-if="!$v.availability.min">{{ $t('availability.min') }}</span>
          <span v-else>{{ $t('availability.required') }}</span>
        </fw-tip>
      </div>
    </div>

    <fw-panel-info size="xs" icon clean>Os horários serão gerados apenas para os dias úteis. </fw-panel-info>

    <fw-panel-info size="xs" icon clean type="orange" boxed
      >Ao gerar os horários, os mesmos não guardados. Se desejar, após sair desta janela, carregue em <b>Guardar</b>.
    </fw-panel-info>

    <fw-panel-info debug label="Data (raw)">
      <json-viewer :value="{ startDate, endDate, startTime, endTime, availability, timeInterval }"></json-viewer>
    </fw-panel-info>

    <div class="flex items-center justify-between gap-3 mt-3">
      <fw-button type="link-muted" @click.native="$emit('close')">
        {{ $t('cancel') }}
      </fw-button>
      <fw-button :type="'primary'" :disable="false" class="w-28" @click.native="createSlots">
        {{ $t('create') }}
      </fw-button>
    </div>
  </fw-panel>
</template>

<script>
import { required, minValue, maxValue } from 'vuelidate/lib/validators'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import NumberInput from '@/fw-modules/fw-core-vue/ui/components/form/NumberInput'
export default {
  components: {
    NumberInput,
  },
  props: {},

  data() {
    return {
      startTime: Dates.now()
        .set('hour', 8)
        .set('minute', 0)
        .set('second', 0)
        .toDate(),
      endTime: Dates.now()
        .set('hour', 17)
        .set('minute', 0)
        .set('second', 0)
        .toDate(),
      startDate: Dates.now()
        .add(1, 'day')
        .toDate(),
      endDate: Dates.now()
        .add(8, 'day')
        .toDate(),
      timeInterval: null,
      minDate: Dates.now().toDate(),
      availability: 1,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    language() {
      return this.$store.state.language
    },
    secondaryLanguage() {
      return this.language === 'pt' ? 'en' : 'pt'
    },
    langs() {
      return [this.language, this.secondaryLanguage]
    },
  },
  validations() {
    return {
      startDate: { required, min: minValue(this.minDate) },
      endDate: { required, min: minValue(this.startDate) },
      startTime: { required },
      endTime: { required, min: minValue(this.startTime) },
      availability: { required, min: minValue(1), max: maxValue(100) },
      timeInterval: { required, min: minValue(1) },
    }
  },

  methods: {
    createSlots() {
      this.$v.$touch()
      console.log('this.$v.$invalid :>> ', this.$v.$invalid)
      if (this.$v.$invalid) return

      this.$emit('create', {
        startTime: this.startTime,
        endTime: this.endTime,
        startDate: this.startDate,
        endDate: this.endDate,
        timeInterval: this.timeInterval,
        availability: this.availability,
      })

      this.$emit('close')
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "create": "Gerar",
    "cancel": "Cancelar",
    "date": {
      "label": "Data",
      "placeholder": "Escolha uma data"
    },
    "startDate": {
      "label": "Data de início",
      "min": "Escolha uma data superior",
      "required": "Insira uma data de início"
    },
    "endDate": {
      "label": "Data de fim",
      "min": "Escolha uma data a seguir a data de início",
      "required": "Insira uma data de fim"
    },
    "time": {
      "label": "Hora",
      "placeholder": "Escolha uma hora"
    },
    "startTime": {
      "label": "Hora de início",
      "min": "Escolha uma hora mais recente",
      "required": "Insira uma hora de início"
    },
    "endTime": {
      "label": "Hora de fim",
      "min": "Escolha uma hora a seguir a hora de início",
      "required": "Insira uma hora de fim"
    },
    "timeInterval": {
      "label": "Tempo das consultas",
      "min": "Insira um valor maior ou igual a 1",
      "required": "Insira um intervalo de tempo em minutos"
    },
    "availability": {
      "label": "Número de vagas",
      "min": "Insira um valor maior ou igual a 1",
      "required": "Insira o número de vagas"
    }
  },
  "en": {
    "create": "Gerar",
    "cancel": "Cancelar",
    "date": {
      "label": "Date",
      "placeholder": "Choose a date"
    },
    "startDate": {
      "label": "Start date",
      "min": "Choose a more recent date",
      "required": "Enter a start date"
    },
    "endDate": {
      "label": "End Date",
      "min": "Choose a date after the start date",
      "required": "Enter an end date"
    },
    "time": {
      "label": "Time",
      "placeholder": "Choose a time"
    },
    "startTime": {
      "label": "Start time",
      "min": "Choose a more recent time",
      "required": "Enter a start time"
    },
    "endTime": {
      "label": "End time",
      "min": "Choose a time after the start time",
      "required": "Enter an end time"
    },
    "timeInterval": {
      "label": "Tempo das consultas",
      "min": "Insira um valor maior ou igual a 1",
      "required": "Insira um intervalo de temppo em minutos"
    },
    "availability": {
      "label": "Número de vagas",
      "min": "Insira um valor maior ou igual a 1",
      "required": "Insira o número de vagas"
    }
  }
}
</i18n>
