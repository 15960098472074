<template>
  <div class="w-full h-full flex flex-col">
    <div class="px-5 py-4 flex flex-col">
      <fw-heading size="h3" muted>
        <div class="flex gap-1 items-center">
          <div class="flex-1">
            Agendamentos
            <fw-badge v-if="reservations" inline type="simple">{{ reservations.length }}</fw-badge>
          </div>
          <div>
            <fw-button type="xlight" class="flex gap-0.5 items-center" @click.native="goback()">
              <fw-icon-chevron-left class="w-5 h-5 text-gray-500 opacity-80 -ml-2" />
              <fw-icon-search class="w-5 h-5 text-gray-500" />
            </fw-button>
          </div>
        </div>
      </fw-heading>
    </div>
    <div v-if="reservations" class="flex flex-1 flex-col gap-2 overflow-y-auto">
      <RecycleScroller
        ref="sidebarReservationsList"
        v-slot="{ item }"
        :items="reservations"
        :item-size="75"
        :buffer="25"
        key-field="key"
        class="px-2"
      >
        <div class="w-full">
          <button
            class="flex p-3 gap-1 my-1 relative w-full text-left group rounded-lg text-sm bg-white flex-col"
            :class="{
              'text-primary shadow-md': item.key == activeReservation,
              'hover:text-gray-800': item.key !== activeReservation,
            }"
            @click="loadReservation(item)"
          >
            <div class="flex gap-2 justify-between items-end w-full">
              <div class="min-h-14">
                <fw-person :person="users?.[item.user_key]" :name-max-lines="2" size="2xs" paddingless />
                <fw-dot :color="item.has_updates ? 'primary' : 'light'" :ping="item.has_updates" class="m-2" />
              </div>
              <div class="flex justify-end">
                <fw-tag v-if="item.present == null" :type="reservationStates[item.state].color">
                  <v-clamp autoresize :max-lines="1">
                    {{ reservationStates[item.state].label[language] }}
                  </v-clamp>
                </fw-tag>
                <fw-tag v-else :type="item.present == true ? 'primary' : 'xlight'">{{
                  item.present == true ? 'Presente' : 'Ausente'
                }}</fw-tag>
              </div>
            </div>
          </button>
        </div>
      </RecycleScroller>
    </div>
    <BlockPagination
      v-if="showPagination"
      :per-page="limit"
      :total="totalItems"
      :total-pages="totalPages"
      :current.sync="page"
      @page-changed="$emit('page-changed', $event)"
    />
  </div>
</template>
<script>
import { RecycleScroller } from 'vue-virtual-scroller'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import { RESERVATION_STATES } from '@/utils/index.js'

export default {
  name: 'SidebarReservationsList',
  components: {
    RecycleScroller,
    BlockPagination,
  },
  props: {
    reservations: {
      type: Array,
      default: () => [],
    },
    activeReservation: {
      type: String,
      default: '',
    },
    users: {
      type: Object,
      default: () => {},
    },
    pagination: {
      type: Object,
      default: () => {},
    },
    /*results: {
      type: Object,
      default: () => {},
    },*/
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      limit: 25,
      reservationStates: RESERVATION_STATES,
    }
  },
  computed: {
    page() {
      return this.pagination && this.pagination.current_page ? this.pagination.current_page : 1
    },
    language() {
      return this.$store.state.language
    },
    showPagination() {
      return this.pagination && this.pagination.total_pages && this.pagination.total_pages > 1 && !this.loading
    },
    totalItems() {
      return this.pagination?.total_items && this.pagination.total_items ? this.pagination.total_items : 0
    },
    totalPages() {
      return this.pagination && this.pagination.total_pages ? this.pagination.total_pages : 0
    },
  },
  mounted() {
    //scroll to active reservation
    if (this.activeReservation) {
      const index = this.reservations.findIndex(reservation => reservation.key == this.activeReservation)
      //this.$refs.sidebarReservationsList.scrollToIndex(index)
      this.$refs.sidebarReservationsList.scrollToItem(index)
    }
  },
  methods: {
    loadReservation(reservation) {
      this.$router.push({
        name: 'manage-appointment-reservation',
        params: { key: this.$route.params.key, reservationKey: reservation.key },
      })
    },
    goback() {
      this.$router.push({ name: `manage-appointment-reservations`, params: { key: this.$route.params.key } })
    },
  },
}
</script>
