<template>
  <div class="relative bulk-import-users">
    <div v-if="processing" class="flex flex-col mx-5 mt-5 mb-2 gap-3 h-96">
      <div>
        <div class="text-lg font-bold">Importar utilizadores</div>
      </div>
      <div class="text-lg text-center text-gray-500 flex-1 flex flex-col justify-center items-center gap-5">
        <fw-icon-user-search class="h-20 text-gray-400"></fw-icon-user-search>
        A processar {{ numberOfLines != 1 ? numberOfLines + ' linhas' : numberOfLines + ' linhas' }}...
      </div>
      <div class="flex gap-3 mb-3">
        <div class="flex-1"></div>
        <fw-button :type="'light'" disabled>Anterior</fw-button>
        <fw-button :type="'regular'" disabled>A carregar</fw-button>
      </div>
    </div>
    <div v-else-if="page == 0" class="flex flex-col mx-5 mt-5 mb-2 gap-3 h-96">
      <div>
        <div class="text-lg font-bold">Importar utilizadores</div>
        <div class="text-sm">
          Insira um número de estudante / funcionário ou email institucional / conta externa por linha:
        </div>
      </div>
      <b-input v-model="users" class="flex-1" type="textarea"></b-input>

      <div class="text-sm text-gray-500 opacity-0" :class="{ 'opacity-100': users.length > 0 }">
        {{ numberOfLines != 1 ? numberOfLines + ' linhas' : numberOfLines + ' linhas' }}
      </div>
      <div class="flex gap-3 mb-3">
        <div class="flex-1"></div>
        <fw-button :type="'light'" @click.native="$emit('close')">Cancelar</fw-button>
        <fw-button :type="'regular'" :disabled="users.length == 0" @click.native="processUsers()">Seguinte</fw-button>
      </div>
    </div>
    <div v-else-if="page == 1" class="flex flex-col mx-5 mt-5 mb-2 gap-3 min-h-96">
      <div>
        <div class="text-lg font-bold">Importar utilizadores</div>
      </div>
      <fw-panel class="flex-1" title="Resultados" :counter="results.length">
        <template #toolbar>
          <div v-if="notfound && notfound > 0" class="text-sm text-gray-500">Não encontrados {{ notfound }}</div>
        </template>
        <div v-if="results.length" class="overflow-auto" style="height: 400px">
          <RecycleScroller
            v-slot="{ item, index }"
            :items="results"
            :item-size="55"
            :buffer="50"
            key-field="key"
            page-mode
          >
            <Person
              :key="item.key"
              :class="{
                'border-b border-gray-100': index !== results.length - 1,
              }"
              :checked="true"
              :person="item"
              :selectable="true"
              :clickable="false"
              @selected="selectPerson(item, $event)"
            >
            </Person>
          </RecycleScroller>
        </div>
        <div v-else class="text-center py-20 text-gray-500">
          Nenhum utilizador encontrado
        </div>
      </fw-panel>
      <div class="flex gap-3 mb-3">
        <div class="flex-1"></div>
        <fw-button :type="'light'" @click.native="page = 0">Anterior</fw-button>
        <fw-button :type="'regular'" :disabled="selected.length == 0" @click.native="confirmSelection"
          >Importar {{ selected.length }}</fw-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { RecycleScroller } from 'vue-virtual-scroller'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/PersonBase'

export default {
  components: { RecycleScroller, Person },
  props: {
    appointment: {
      type: Object,
      default: () => {},
    },
    excludeUsers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      users: '',
      processing: false,
      page: 0,
      results: [],
      notfound: [],
      selected: [],
      timer: null,
    }
  },
  computed: {
    numberOfLines() {
      return this.users.split(/\r\n|\r|\n/).length
    },
    api() {
      return this.$store.state.api.base
    },
  },
  methods: {
    selectPerson(person, selectedValue) {
      console.log(person, selectedValue)
      let i = this.selected.findIndex(el => el.key == person.key)
      if (i >= 0 && !selectedValue) {
        this.selected.splice(i, 1)
      }
      if (selectedValue && i == -1) {
        this.selected.push(person)
      }
    },
    confirmSelection() {
      if (this.selected.length > 0) {
        this.$emit('selected', this.selected)
        this.$emit('close')
      }
    },
    showError(message = 'Ocorreu um erro. Tente de novo mais tarde ou contacte o suporte.') {
      this.$buefy.dialog.alert({
        message: message,
        type: 'is-danger',
        hasIcon: true,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true,
      })
    },
    async processUsers() {
      if (this.users.length == 0) {
        return
      }
      let query = this.users.split(/\r\n|\r|\n/).join(',')
      this.processing = true
      try {
        let result = await this.api.bulkSearchAppointmentUsers(this.appointment.key, {
          q: query,
          exclude: this.excludeUsers,
        })
        console.log(result)
        if (this.timer != null) {
          clearTimeout(this.timer)
        }
        this.timer = setTimeout(() => {
          this.results = result.data
          this.selected = result.data
          this.notfound = result.not_found
          this.page = 1
          this.processing = false
        }, 1000)
      } catch (e) {
        console.error(e)
        this.processing = false
        this.showError('Ocorreu um erro ao encontrar os utilizadores')
      }
    },
  },
}
</script>

<style>
.bulk-import-users .textarea {
  @apply h-full;
}
</style>
