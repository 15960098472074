<template>
  <fw-panel title="Agendamentos" featured>
    <ContextualSearch
      v-if="showSearch"
      :loading="loading"
      :filter-options="filters"
      :applied-filters="appliedFilters"
      :applied-sort="orderByValue"
      :applied-sort-direction="orderDirection"
      :applied-users="userFilters"
      :multifilter="multifilter"
      :start-value="searchInput"
      :start-period="startPeriod"
      :end-period="endPeriod"
      :order-by-options="orderBy"
      :users="users"
      :available-users-keys="managersKeys"
      :show-time-period="true"
      :show-user-picker="true"
      :user-picker-label="'Responsáveis'"
      :time-period-label="$t('createdDate')"
      :can-close="activeModal === null"
      :scrollable="false"
      @sort-order-changed="sortOrderChanged"
      @search="search"
    >
      <template #tags>
        <FilterTag
          v-for="(userKey, u) in userFilters"
          :key="'filter_user_' + userKey"
          :text="users[userKey].name ?? 'Sem nome'"
          :show-close-button="true"
          @close="deleteUserFilter(u)"
        ></FilterTag>
        <FilterTag
          v-for="(filter, f) in appliedFilters"
          :key="'filter_' + f"
          :text="getFilterText(filter)"
          :show-close-button="true"
          @close="deleteFilter(f)"
        ></FilterTag>
        <FilterTag
          v-if="startPeriod != null && endPeriod != null"
          :text="startPeriod + ' - ' + endPeriod"
          :show-close-button="true"
          @close="deleteDates()"
        ></FilterTag>
      </template>
    </ContextualSearch>

    <slot name="stats" />

    <fw-panel
      :title="$t('results')"
      :counter="reservations ? reservations.length : 0"
      :counter-total="totalResults"
      boxed
      class="my-5"
      custom-class="bg-white p-0"
    >
      <fw-panel-info v-if="!loading && (!reservations || !reservations.length)" type="basic">
        {{ $t('noReservations') }}
      </fw-panel-info>

      <LoadingPlaceholder v-if="loading" />

      <RecycleScroller
        v-else
        ref="reservationsList"
        v-slot="{ item }"
        :items="reservations"
        :item-size="50"
        :buffer="25"
        key-field="key"
        page-mode
      >
        <div class="p-1 border-b border-gray-100">
          <RecordReservation
            :reservation="item"
            :appointment-code="appointment.prefix + appointment.code"
            :user="users[item.user_key]"
            :users="users"
            @open="goToReservation(item.key)"
          ></RecordReservation>
        </div>
      </RecycleScroller>
    </fw-panel>

    <BlockPagination
      v-if="totalPages > 1 && !loading"
      :per-page="limit"
      :total="totalResults"
      :total-pages="totalPages"
      :current.sync="page"
      @page-changed="pageChanged"
    />
  </fw-panel>
</template>

<script>
import ContextualSearch from '@/fw-modules/fw-core-vue/ui/components/search/ContextualSearch'
import FilterTag from '@/fw-modules/fw-core-vue/ui/components/text/FilterTag.vue'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import RecordReservation from '@/components/records/RecordReservation'

import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import { RecycleScroller } from 'vue-virtual-scroller'

export default {
  components: {
    ContextualSearch,
    FilterTag,
    BlockPagination,
    LoadingPlaceholder,
    RecordReservation,
    RecycleScroller,
  },

  props: {
    showSearch: {
      type: Boolean,
      default: true,
    },
    multifilter: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    appointment: {
      type: Object,
      default: () => {},
    },
    users: {
      type: Object,
      default: () => {},
    },
    results: {
      type: Object,
      default: () => {},
    },
    peopleValidations: {
      type: Object,
      default: () => {},
    },
    activeReservationKey: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      filterByType: 'all',
      filters: [
        // {
        //   key: 'type',
        //   label: this.$t('spaceType.label'),
        //   options: [
        //     {
        //       key: 'reset',
        //       label: this.$t('all'),
        //     },
        //     {
        //       key: 'development',
        //       label: this.$t('spaceType.development'),
        //     },
        //     {
        //       key: 'support',
        //       label: this.$t('spaceType.support'),
        //     },
        //   ],
        // },
      ],
      orderBy: [
        {
          key: 'created_at',
          label: this.$t('orderBy.createdDate'),
          type: 'date',
        },
      ],
      page: 1,
      totalResults: 0,
      totalPages: 1,
      limit: 25,
      searchInput: '',
      orderByValue: 'created_at',
      orderDirection: 'DESC',
      appliedFilters: [],
      reservations: [],
      startPeriod: null,
      endPeriod: null,
      activeModal: null,
      managersKeys: [],
      userFilters: [],

      distribution: {
        activeModal: false,
        actualState: {},
        running: false,
        results: {},
      },
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    user() {
      return this.$store.getters.getUser
    },
    language() {
      return this.$store.state.language
    },
    canExecuteDistribution() {
      return (
        this.distribution.actualState &&
        this.distribution.actualState.reservations > 0 &&
        this.distribution.actualState.managers.length
      )
    },
    isManagerOrAppointmentManager() {
      return this.peopleValidations && this.peopleValidations.can_edit_workers
    },
  },

  watch: {
    results() {
      this.totalResults = this.results.pagination?.total_items
      this.totalPages = this.results.pagination?.total_pages
      this.page = this.results.pagination?.current_page
      this.reservations = this.results.reservations
      this.managersKeys = this.results.filters['manager[]'] //.managers
      console.log(this.results)
      this.filters = [
        {
          key: 'state',
          label: this.$t('status'),
          options: this.results.filters['state[]'].map(el => {
            return {
              key: el,
              label: this.$t('statusLabel.' + el),
            }
          }),
          //   options: [
        },
      ]
    },
  },

  mounted() {
    utils.sleep(100).then(() => {
      this.getUrlParams()
      this.getReservations()
      this.scrollToActiveReservation()
    })
  },

  methods: {
    // Reservations distribuition
    async openReservationsDistributionModal() {
      utils.tryAndCatch(this, async () => {
        this.distribution.actualState = await this.api.getAppointmentReservationsDistribution(this.appointment.key)
        if (this.distribution.actualState.validations.can_click) {
          this.distribution.activeModal = true
        } else {
          console.log('Modal will not be opened because user does not have permission.')
        }
        console.log('Appointment distribution', this.distribution)
      })
    },
    async doAppointmentReservationsDistribution() {
      this.distribution.running = true
      utils.tryAndCatch(
        this,
        async () => {
          this.distribution.results = await this.api.doAppointmentReservationsDistribution(this.appointment.key)
          console.log('Result of appointment distribution execution', this.distribution.results)
          this.$emit('search')
        },
        () => {
          setTimeout(() => {
            this.distribution.running = false
          }, 1000)
        }
      )
    },
    closeDistributionModal() {
      this.distribution.activeModal = false
      // Make sure user does not see a glitch when closing the modal
      setTimeout(() => {
        this.distribution.running = false
        this.distribution.results = {}
      }, 250)
    },

    scrollToActiveReservation() {
      //scroll to active reservation
      if (this.activeReservationKey) {
        const index = this.reservations.findIndex(reservation => reservation.key == this.activeReservationKey)
        //this.$refs.sidebarReservationsList.scrollToIndex(index)
        this.$refs.reservationsList.scrollToItem(index)
      }
    },
    goToReservation(key) {
      this.$router.push({
        name: 'manage-appointment-reservation',
        params: { key: this.$route.params.key, reservationKey: key },
      })
    },

    deleteFilter(index) {
      this.appliedFilters.splice(index, 1)
      this.setUrlParams()
      this.getReservations()
    },

    deleteUserFilter(index) {
      this.userFilters.splice(index, 1)
      this.setUrlParams()
      this.getReservations()
    },

    deleteDates() {
      this.startPeriod = null
      this.endPeriod = null
      this.setUrlParams()
      this.getReservations()
    },

    getFilterText(key) {
      return utils.getFilterText(key, this.filters)
    },

    search(data) {
      console.log(data)
      this.appliedFilters = JSON.parse(JSON.stringify(data.filters))
      this.searchInput = data.term

      if (data.orderBy != null) {
        this.orderByValue = data.orderBy
        this.orderDirection = data.orderDirection
      }

      this.startPeriod = data.dates.length == 2 ? this.$options.filters.formatDate(data.dates[0]) : null
      this.endPeriod = data.dates.length == 2 ? this.$options.filters.formatDate(data.dates[1]) : null

      this.userFilters = data.filterUsers

      this.$emit('searching')

      this.setUrlParams()
      this.getReservations()
    },

    getUrlParams() {
      if (this.$route.query.q) {
        this.searchInput = this.$route.query.q
      }

      if (this.$route.query.f) {
        this.appliedFilters = this.$route.query.f.split(',')
      }

      if (this.$route.query.s) {
        this.orderByValue = this.$route.query.s
        this.orderDirection =
          this.$route.query.o == 'ASC' || this.$route.query.o == 'DESC' ? this.$route.query.o : 'none'
      }

      if (this.$route.query.p) {
        this.page = parseInt(this.$route.query.p)
      }

      if (this.$route.query.start) {
        this.startPeriod = this.$route.query.start
      }

      if (this.$route.query.end) {
        this.endPeriod = this.$route.query.end
      }
    },

    setUrlParams() {
      let query = {}
      if (this.searchInput.length > 0) {
        query['query'] = this.searchInput
      }

      if (this.appliedFilters.length > 0) {
        query['f'] = this.appliedFilters.join(',')
      }

      if (this.orderByValue.length > 0) {
        query['s'] = this.orderByValue
        query['o'] = this.orderDirection
      }

      if (this.startPeriod != null && this.endPeriod != null) {
        query['start'] = this.startPeriod
        query['end'] = this.endPeriod
      }

      query['p'] = this.page

      this.$router.push({ path: this.$route.path, query: query })
    },

    sortOrderChanged(newSort) {
      if (newSort != null && newSort.key != null) {
        this.orderByValue = newSort.key.key
        this.orderDirection = newSort.direction
      }
      this.setUrlParams()
      this.getReservations()
    },

    async getReservations() {
      const maxNumber = this.maxNumberSpaces != null ? this.maxNumberSpaces : this.limit
      const query = { limit: maxNumber, page: this.page, ...utils.setFiltersQuery(this.appliedFilters) }
      if (this.orderByValue.length > 0) {
        query['sort'] = this.orderByValue
        query['direction'] = this.orderDirection.toLowerCase()
      }

      if (this.searchInput.length > 0) {
        query['query'] = this.searchInput
      }

      if (this.startPeriod && this.endPeriod) {
        query['created_start'] = this.parseDatesForPayload(this.startPeriod)
        query['created_end'] = this.parseDatesForPayload(this.endPeriod)
      }

      if (this.userFilters.length > 0) {
        query['manager'] = this.userFilters
      }

      this.$emit('search', query)
    },

    pageChanged(page) {
      console.log('pageChanged to :>> ', page)
      if (page) this.page = page
      this.setUrlParams()
      this.getReservations()
    },

    parseDatesForPayload(formatedDate) {
      if (formatedDate) {
        return Dates.from(formatedDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
      }
      return formatedDate
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "createdDate": "Data de criação",
    "results": "Resultados",
    "noReservations": "Sem agendamentos para apresentar.",
    "all": "Todos",
    "orderBy": {
      "createdDate": "Data de criação"
    },
    "status": "Estado",
    "statusLabel": {
      "submitted": "Reservada",
      "in_analysis": "Em análise",
      "in_analysis_waiting": "Em análise (a aguardar resposta)",
      "complaint": "Audiência de interessados",
      "complaint_analysis": "Audiência de interessados (em análise)",
      "waiting_rejection": "Em análise (sem resposta)",
      "complaint_waiting_rejection": "Audiência de interessados (sem resposta)",
      "in_progress": "Em progresso",
      "closed": "Cancelada"
    }
  },
  "en": {
    "createdDate": "Creation date",
    "results": "Results",
    "noReservations": "No reservations to present.",
    "all": "All",
    "orderBy": {
      "createdDate": "Creation date"
    },
    "status": "Status",
    "statusLabel": {
      "submitted": "Reservada",
      "in_analysis": "In analysis",
      "in_analysis_waiting": "In analysis - waiting",
      "complaint": "Complaint",
      "complaint_analysis": "Complaint analysis",
      "in_progress": "In progress",
      "closed": "Cancelada"
    }
  }
}
</i18n>
