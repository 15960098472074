<template>
  <div
    class="relative rounded-lg px-3 py-2.5 hover:bg-gray-200 hover:bg-opacity-50 group w-full flex gap-5 items-center cursor-pointer"
    @click="$emit('open', reservation.key)"
  >
    <div>
      <fw-avatar size="xs" :user="user" class="flex-shrink-0" />
    </div>
    <div class="flex-1 text-left">
      <v-clamp autoresize :max-lines="1" class="font-medium">
        {{ user.full_name }}
      </v-clamp>
      <div class="leading-3 text-xs text-gray-400">{{ user.number }}222</div>
    </div>
    <div class="text-xs text-gray-500 w-36 text-left flex gap-2 items-center">
      <fw-dot :color="reservation.has_updates ? 'primary' : 'light'" inline :ping="reservation.has_updates" />
      <span>{{ reservation.updated_date ?? reservation.created_date | formatDateTime }}</span>
    </div>
    <div class="w-20">
      <fw-tag v-if="reservation.present == null" :type="reservationStates[reservation.state].color" expanded>
        <v-clamp autoresize :max-lines="1">
          {{ reservationStates[reservation.state].label[language] }}
        </v-clamp>
      </fw-tag>
      <fw-tag v-else :type="reservation.present == true ? 'primary' : 'xlight'" expanded>{{
        reservation.present == true ? 'Presente' : 'Ausente'
      }}</fw-tag>
    </div>
    <div class="w-8">
      <div v-if="firstManager" class="relative">
        <fw-avatar size="xs" :user="firstManager" />
        <fw-badge v-if="moreManagersTotal" class="-mr-1">+{{ moreManagersTotal }}</fw-badge>
      </div>
      <div v-else class="flex items-center justify-center">
        <fw-icon-user-unfollow class="text-gray-300 w-8 h-8 bg-gray-50 rounded-lg p-1" />
      </div>
    </div>
  </div>
</template>

<script>
import { RESERVATION_STATES } from '@/utils/index.js'

export default {
  props: {
    reservation: {
      type: Object,
      default: () => {},
    },
    user: {
      type: Object,
      default: () => {},
    },
    users: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      reservationStates: RESERVATION_STATES,
    }
  },

  computed: {
    language() {
      return this.$store.state.language
    },
    firstManager() {
      return this.reservation &&
        this.reservation.managers &&
        this.reservation.managers.length &&
        this.users &&
        this.users[this.reservation.managers[0]]
        ? this.users[this.reservation.managers[0]]
        : null
    },
    moreManagersTotal() {
      return this.firstManager && this.reservation.managers.length > 1 ? this.reservation.managers.length - 1 : 0
    },
  },
}
</script>

<i18n>
{
  "pt": {},
  "en": {}
}
</i18n>
