<template>
  <SidebarBase sticky>
    <template #main>
      <div class="flex-1">
        <ButtonSidebar
          :active="!view || view == 'dashboard'"
          :label="$t('dashboard')"
          icon="dashboard"
          @click.native="goTo('dashboard')"
        />
        <ButtonSidebar :active="view == 'slots'" :label="$t('slots')" icon="cube" @click.native="goTo('slots')" />
        <ButtonSidebar
          v-if="!isWorker"
          :active="view == 'people'"
          :label="$t('applicants')"
          icon="people"
          @click.native="goTo('people')"
        />
        <ButtonSidebar
          :active="view == 'reservations' || view == 'reservation'"
          :label="$t('reservations')"
          icon="calendar-event"
          @click.native="goTo('reservations')"
        />
        <ButtonSidebar
          v-if="!isWorker"
          :label="$t('activity')"
          :active="view == 'activity'"
          icon="activity"
          @click.native="goTo('activity')"
        />
        <ButtonSidebar
          v-if="!isWorker"
          :label="$t('notifications')"
          :active="view == 'notifications'"
          icon="message-sent"
          @click.native="goTo('notifications')"
        />
        <ButtonSidebar
          v-if="!isWorker"
          :active="view == 'settings'"
          :label="$t('settings')"
          icon="settings"
          @click.native="goTo('settings')"
        />
      </div>
    </template>
  </SidebarBase>
</template>

<script>
import SidebarBase from '@/fw-modules/fw-core-vue/ui/components/sidebars/SidebarBase'
import ButtonSidebar from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonSidebar'

export default {
  components: {
    ButtonSidebar,
    SidebarBase,
  },

  props: {
    currentQueue: {
      type: String,
    },
    queues: {
      type: Array,
      default: () => [],
    },
    validations: {
      type: Object,
      default: () => {},
    },
    appointmentType: {
      type: String,
      default: 'scholarship',
    },
    peopleValidations: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    appointmentKey() {
      return this.$route.params.key
    },
    view() {
      return this.$route.meta.view ?? 'dashboard'
    },
    isWorker() {
      return (
        this.peopleValidations && !this.peopleValidations.can_edit_managers && !this.peopleValidations.can_edit_workers
      )
    },
  },

  methods: {
    goTo(routeName) {
      this.$router.push({ name: `manage-appointment-${routeName}`, params: { key: this.appointmentKey } })
    },
  },
}
</script>

<style lang="postcss">
a span:last-child {
  @apply text-black;
}
</style>

<i18n>
{
  "pt": {
    "dashboard": "Dashboard",
    "activity": "Atividade",
    "people": "Pessoas",
    "queues": "Filas",
    "notifications": "Notificações",
    "settings": "Configurações",
    "metadata": "Metadados",
    "reservations": "Agendamentos",
    "applicants": "Trabalhadores",
    "slots": "Horários"
  },
  "en": {
    "dashboard": "Dashboard",
    "activity": "Activity",
    "people": "People",
    "queues": "Queues",
    "notifications": "Notifications",
    "settings": "Settings",
    "metadata": "Metadata",
    "reservations": "Agendamentos",
    "applicants": "Applicants",
    "slots": "Horários"
  }
}
</i18n>
