<template>
  <div>
    <fw-panel :title="'Horários'" custom-class="max-h-[80vh] overflow-y-auto">
      <div v-if="!slotsAreEmpty">
        <b-collapse
          v-for="(daySlots, day) in slots"
          :key="day"
          :open="startOpen"
          class="bg-white rounded-xl px-4 py-3 flex flex-col gap-2"
          :aria-id="day"
        >
          <template #trigger="props">
            <div
              class="inline-flex items-center justify-between w-full"
              role="button"
              :aria-controls="day"
              :aria-expanded="props.open"
            >
              <div class="font-semibold flex-1">
                <div class="text-lg">{{ day | formatDate }}</div>
                <div class="text-sm text-gray-500">
                  {{ $t(`weekdays.${getWeekDay(day)}`) }}
                </div>
              </div>

              <div class="w-5 h-5">
                <fw-icon-chevron-up v-if="props.open" class="w-5 h-5" />
                <fw-icon-chevron-down v-else class="w-5 h-5 " />
              </div>
            </div>
          </template>

          <div class="w-full flex flex-col gap-2">
            <RecordSlot
              v-for="(slot, slotI) in daySlots"
              :key="slotI"
              :time-slot="slot"
              :selected="slot.key == slotKey"
              :show-actions="showActions"
              @select="$emit('update:slotKey', $event)"
            ></RecordSlot>
          </div>
        </b-collapse>
      </div>
      <fw-panel-info v-else clean>Ainda não foram definidos horários para esta chamada.</fw-panel-info>

      <fw-panel-info debug label="Data (raw)">
        <json-viewer :value="{ slots, slotKey }"></json-viewer>
      </fw-panel-info>
    </fw-panel>

    <div v-if="withFooter" class="flex items-center justify-between gap-3 mt-5">
      <fw-button type="link-muted" @click.native="$emit('close')">
        Cancelar
      </fw-button>
      <fw-button :type="'primary'" :disable="false" class="w-28" @click.native="$emit('save')">
        Guardar
      </fw-button>
    </div>
  </div>
</template>

<script>
import RecordSlot from '@/components/records/RecordSlot'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    RecordSlot,
  },

  props: {
    slotKey: {
      type: String,
      default: null,
    },

    savingData: {
      type: Boolean,
    },

    slots: {
      type: Object,
      default: () => {},
    },

    startOpen: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    showActions: {
      type: Boolean,
      default: true,
    },

    withFooter: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    language() {
      return this.$store.state.language
    },
    user() {
      return this.$store.getters.getUser
    },
    secondaryLanguage() {
      return this.language === 'pt' ? 'en' : 'pt'
    },
    langs() {
      return [this.language, this.secondaryLanguage]
    },
    slotsAreEmpty() {
      return utils.isObjectEmpty(this.slots)
    },
  },

  methods: {
    getWeekDay(date) {
      return Dates.buildCore(date).day()
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "weekdays": {
      "0": "Domingo",
      "1": "Segunda-feira",
      "2": "Terça-feira",
      "3": "Quarta-feira",
      "4": "Quinta-feira",
      "5": "Sexta-feira",
      "6": "Sábado"
    }
  },
  "en": {
    "weekdays": {
      "0": "Sunday",
      "1": "Monday",
      "2": "Tuesday",
      "3": "Wednesday",
      "4": "Thursday",
      "5": "Friday",
      "6": "Saturday"
    }
  }
}
</i18n>
