<template>
  <fw-panel v-if="reservation" :title="'Horários reservados'" class="py-5">
    <template v-if="canEdit" #toolbar>
      <fw-button type="link" @click.native="isActiveSlotsModal = true">Escolher slot</fw-button>
    </template>

    <div v-if="activeSlot" class="bg-white rounded-xl px-4 py-3 flex flex-col gap-2">
      <div class="inline-flex items-center justify-between w-full" role="button">
        <div class="font-semibold flex-1">
          <div class="text-lg">{{ activeSlot.start_datetime | formatDate }}</div>
          <div class="text-sm text-gray-500">
            {{ $t(`weekdays.${getWeekDay(activeSlot.start_datetime)}`) }}
          </div>
        </div>
      </div>

      <RecordSlot
        :time-slot="activeSlot"
        :active="true"
        class="mb-2 cursor-pointer"
        :show-actions="false"
        :show-availability="false"
        @click="goToReservation(activeSlot)"
      >
        <div>Reservado</div>

        <template v-if="slotUsers?.[activeSlot.key]" #secondline>
          <div class="flex items-center gap-4 mt-3">
            <div v-for="r in slotUsers[activeSlot.key]" :key="r.key">
              <fw-button
                type="link"
                class="cursor-pointer !text-white"
                paddingless
                @click.native="
                  $router.push({
                    name: 'manage-appointment-reservation',
                    params: {
                      key: appointment?.key,
                      reservationKey: r?.key,
                    },
                  })
                "
              >
                <div class="flex gap-2 items-center font-normal">
                  <div>
                    <fw-avatar size="xs" :user="users?.[r.user_key]" />
                  </div>
                  <div class="text-left">
                    <v-clamp class="text-sm font-semibold" autoresize :max-lines="1">{{
                      users?.[r.user_key].name
                    }}</v-clamp>
                    <div class="flex gap-2">
                      <span class="text-xs">{{ users?.[r.user_key].number }}</span>
                      <v-clamp class="text-xs" autoresize :max-lines="1">{{ users?.[r.user_key].email }}</v-clamp>
                    </div>
                  </div>
                </div>
              </fw-button>
            </div>
          </div>
        </template>
      </RecordSlot>
    </div>

    <b-collapse
      v-for="(daySlots, day) in cancelledSlots"
      :key="day"
      class="bg-white rounded-xl px-4 py-3 flex flex-col gap-2 mt-3"
      :aria-id="day"
    >
      <template #trigger="props">
        <div
          class="inline-flex items-center justify-between w-full"
          role="button"
          :aria-controls="day"
          :aria-expanded="props.open"
        >
          <div class="font-semibold flex-1">
            <div class="text-lg">{{ day | formatDate }}</div>
            <div class="text-sm text-gray-500">
              {{ $t(`weekdays.${getWeekDay(day)}`) }}
            </div>
          </div>

          <div class="w-5 h-5">
            <fw-icon-chevron-up v-if="props.open" class="w-5 h-5" />
            <fw-icon-chevron-down v-else class="w-5 h-5 " />
          </div>
        </div>
      </template>

      <div class="w-full flex flex-col gap-2">
        <RecordSlot
          v-for="(slot, slotI) in daySlots"
          :key="slotI"
          :time-slot="slot"
          class=" cursor-pointer"
          :show-actions="false"
          :show-availability="false"
          @click="goToReservation(slot.reservation_key)"
        >
          <div>
            Cancelado
            {{
              slot.canceled_by === loggedUser.key
                ? 'por si'
                : slot.canceled_by == slot.user_key
                ? 'pelo trabalhador'
                : `por ${users?.[slot.canceled_by]?.full_name}`
            }}
          </div>
        </RecordSlot>
      </div>
    </b-collapse>

    <fw-panel-info v-if="!reservation.time_slot && !reservation?.reservations?.length" clean
      >Ainda não foram reservados slots .</fw-panel-info
    >

    <fw-panel-info debug label="Data (raw)">
      <json-viewer
        :value="{ slots, cancelledSlots, activeSlot: reservation.time_slot, reservation, users }"
      ></json-viewer>
    </fw-panel-info>

    <fw-modal
      v-if="isActiveSlotsModal"
      :active.sync="isActiveSlotsModal"
      boxed="sm"
      size="min"
      width="42rem"
      @close="isActiveSlotsModal = false"
    >
      <PanelSlots
        :slots="slots"
        :slot-key.sync="slotKey"
        @save="confirmNewSlot"
        @close="isActiveSlotsModal = false"
      ></PanelSlots>
    </fw-modal>
  </fw-panel>
</template>

<script>
import PanelSlots from '@/components/panels/occupationalMedicine/PanelSlots'
import RecordSlot from '@/components/records/RecordSlot'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import groupBy from 'lodash/groupBy'

export default {
  components: {
    PanelSlots,
    RecordSlot,
  },

  props: {
    reservation: {
      type: Object,
      default: () => {
        return {
          slot_key: null,
        }
      },
    },

    appointment: {
      type: Object,
      default: () => {
        return {}
      },
    },

    users: {
      type: Object,
      default: () => {
        return {}
      },
    },

    savingData: {
      type: Boolean,
    },

    slots: {
      type: Object,
      default: () => {},
    },

    loading: {
      type: Boolean,
      default: false,
    },

    canEdit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isActiveSlotsModal: false,
      slotKey: null,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    language() {
      return this.$store.state.language
    },
    loggedUser() {
      return this.$store.getters.getUser
    },
    secondaryLanguage() {
      return this.language === 'pt' ? 'en' : 'pt'
    },
    langs() {
      return [this.language, this.secondaryLanguage]
    },

    activeSlot() {
      return this.reservation?.active_reservation?.time_slot
    },

    cancelledSlots() {
      if (!this.reservation?.reservations) return []
      const slots = this.reservation.reservations.map(el => {
        return { ...el.time_slot, canceled_by: el.canceled_by, user_key: el.user_key, reservation_key: el.key }
      })

      return groupBy(slots, x => Dates.buildCore(x.start_datetime).format('YYYY-MM-DD'))
    },

    slotUsers() {
      const slotUsers = {}
      for (const slot of Object.values(this.slots).flat()) {
        if (slot.reservations?.length) {
          slotUsers[slot.key] = slot.reservations
        }
      }
      return slotUsers
    },
  },

  mounted() {
    this.slotKey = this.reservation.slot_key
  },

  methods: {
    goToReservation(key) {
      this.$router.push({
        name: 'manage-appointment-reservation',
        params: { key: this.$route.params.key, reservationKey: key },
      })
    },

    getWeekDay(date) {
      return Dates.buildCore(date).day()
    },

    confirmNewSlot() {
      this.$buefy.dialog.confirm({
        type: 'is-black',
        title: 'Tem a certeza que deseja guardar o slot?',
        message: 'Os agendamentos anteriores serão cancelados.',
        confirmText: 'Confirmar',
        cancelText: 'Fechar',
        onConfirm: () => {
          this.$emit('save', this.slotKey)
          this.isActiveSlotsModal = false
        },
      })
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "weekdays": {
      "0": "Domingo",
      "1": "Segunda-feira",
      "2": "Terça-feira",
      "3": "Quarta-feira",
      "4": "Quinta-feira",
      "5": "Sexta-feira",
      "6": "Sábado"
    }
  },
  "en": {
    "weekdays": {
      "0": "Sunday",
      "1": "Monday",
      "2": "Tuesday",
      "3": "Wednesday",
      "4": "Thursday",
      "5": "Friday",
      "6": "Saturday"
    }
  }
}
</i18n>
